<template>
  <div>
    <div class="page_header_title_box" v-if="isShow">
      <i class="el-icon-back" @click="handleCancel"></i>
      新增膜品类型
    </div>
    <div class="page_header_title_box" v-else>
      <i class="el-icon-back" @click="handleCancel"></i>
      编辑膜品类型
    </div>
    <div style="padding: 70px 20px 20px 20px">
      <div class="content_box">
        <el-form
          label-width="150px"
          :rules="rules"
          ref="formData"
          :model="formData"
          v-loading="vloading"
        >
          <el-form-item label="膜品类型名称" prop="name">
            <el-input
              size="mini"
              style="width: 300px"
              v-model="formData.name"
              placeholder="不超过40个字"
              maxlength="40"
            >
            </el-input>
          </el-form-item>
          <el-form-item label="切膜次数" prop="count">
            <el-input
              size="mini"
              style="width: 300px"
              v-model="formData.count"
              placeholder="请输入切割次数"
              maxlength="40"
            >
            </el-input>
          </el-form-item>
          <el-form-item label="膜码前缀" prop="preCode">
            <el-input
              size="mini"
              style="width: 300px"
              v-model="formData.preCode"
              placeholder="请输入膜码前缀"
              maxlength="40"
            >
            </el-input>
          </el-form-item>
          <el-form-item label="赠送次数" prop="name">
            <el-input
              size="mini"
              style="width: 300px"
              v-model="formData.reCount"
              placeholder="请输入赠送次数"
              maxlength="40"
            >
            </el-input>
          </el-form-item>
          <el-form-item label="状态">
            <el-radio-group v-model="formData.openFlag">
              <el-radio :label="1">启用</el-radio>
              <el-radio :label="0">禁用</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="膜品类型描述">
            <el-input
              style="width: 50%"
              size="mini"
              type="textarea"
              :rows="4"
              v-model="formData.remark"
              placeholder="不超过200个字"
              maxlength="200"
            ></el-input>
          </el-form-item>
          <el-form-item label="关联货品" prop="itemCodes">
            <el-button class="mini-search-btn" @click="itemsSelectShow()"
              >新增</el-button
            >
            <div>
              <el-table
                :data="goodsItems"
                header-row-class-name="header-row"
                class="tableCenter"
                v-loading="loading"
              >
                <el-table-column
                  label="货品编码"
                  align="center"
                  prop="itemCode"
                ></el-table-column>
                <el-table-column
                  label="货品名称"
                  align="center"
                  prop="itemName"
                ></el-table-column>
                <el-table-column
                  label="上架状态"
                  align="center"
                  prop="saleStatus"
                  :formatter="formatSaleStatus"
                  width="180"
                ></el-table-column>
                <el-table-column label="操作" width="100" align="center">
                  <template slot-scope="scope">
                    <el-button
                      class="mini-delete-btn"
                      @click="handleDeleteGoodsItems(scope.$index)"
                      >删除
                    </el-button>
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </el-form-item>
        </el-form>
        <el-dialog
          title="添加货品"
          :modal-append-to-body="false"
          :close-on-click-modal="false"
          :visible="itemsShow"
          width="80%"
          :before-close="closehandleShow"
        >
          <selectItem
            v-if="itemsShow"
            :selectItemsData="goodsItems"
            :saleStatus="3"
            :goodsType="1"
            ref="selectGoodItems"
            @cancel="itemsShow = false"
            @submit="getItemsData"
          ></selectItem>
        </el-dialog>
        <div class="clearfix footbtn">
          <el-button
            class="mini-search-btn"
            :loading="dloading"
            style="margin-left: 46.5%"
            @click="handleSubmit()"
            >保存</el-button
          >
          <el-button size="mini" @click="handleCancel()">返回</el-button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import api from "@/api/allUrl";
import selectItem from "@/components/selectItem";
export default {
  name: "add_filmtype",
  components: {
    selectItem,
  },
  data() {
    return {
      goodsItems: [],
      isShow: true,
      dloading: false,
      vloading: false,
      loading: false,
      formData: {
        count: 0, //切割次数
        itemCodes: [],
        name: "", //膜码类型名称
        openFlag: 1, //状态, 1.启用 0.禁用
        preCode: "", //膜码前缀
        reCount: 0, //赠送次数
        remark: "", //膜码类型描述
      },
      rules: {
        name: [
          {
            required: true,
            message: "请输入膜品类型名称",
            trigger: "blur",
          },
        ],
        count: [
          {
            required: true,
            message: "请输入切割次数",
            trigger: "blur",
          },
        ],
        preCode: [
          {
            required: true,
            message: "请输入膜码前缀",
            trigger: "blur",
          },
        ],
        reCount: [
          {
            required: true,
            message: "请输入赠送次数",
            trigger: "blur",
          },
        ],
        // itemCodes: [{
        //     required: true,
        //     message: '请选择需要关联的货品信息',
        //     trigger: 'blur'
        // }]
      },
      itemsShow: false,
    };
  },
  activated() {
    this.goodsItems = [];
    this.isShow = true;
    this.dloading = false;
    this.vloading = false;
    this.loading = false;
    this.formData = {
      count: 0, //切割次数
      itemCodes: [],
      name: "", //膜码类型名称
      openFlag: 1, //状态, 1.启用 0.禁用
      preCode: "", //膜码前缀
      reCount: 0, //赠送次数
      remark: "", //膜码类型描述
    };

    if (this.$route.query.checkMsg == 3) {
      this.isShow = false;
      this.vloading = true;
      this.getFilmtypeDetail();
    } else {
      this.isShow = true;
    }
  },
  methods: {
    getFilmtypeDetail() {
      this.$api
        .get(this, api.getFilmtypeDetail, {
          id: this.$route.query.id,
        })
        .then((res) => {
          this.formData = res.data;
          this.formData.itemCodes = [];
          if (res.data.goodsItems != undefined) {
            this.goodsItems = res.data.goodsItems;
            res.data.goodsItems.forEach((item) => {
              this.formData.itemCodes.push(item.itemCode);
            });
          }
          this.vloading = false;
        });
    },
    // 必填验证
    handleSubmit() {
      this.dloading = true;
      this.$refs["formData"].validate((valid) => {
        if (valid) {
          if (this.formData.id == undefined || this.formData.id == "") {
            this.$api.post(this, api.addFilmtype, this.formData).then((res) => {
              if (res.success) {
                this.$message.success({
                  message: "新增膜品类型成功",
                  duration: 3 * 1000,
                });
                this.handleCancel();
              }
              this.dloading = false;
            });
          } else {
            this.$api
              .put(this, api.updateFilmtypeDetail, this.formData)
              .then((res) => {
                if (res.success) {
                  this.$message.success({
                    message: "膜品类型修改成功",
                    duration: 3 * 1000,
                  });
                  this.handleCancel();
                }
                this.dloading = false;
              });
          }
        } else {
          this.$message({
            message: "请先将膜品类型信息补充完整",
            type: "error",
            duration: 3 * 1000,
            onClose: () => {},
          });
          this.dloading = false;
        }
      });
    },
    // 删除货品
    handleDeleteGoodsItems(index) {
      this.goodsItems.splice(index, 1);
      this.formData.itemCodes.splice(index, 1);
    },
    formatSaleStatus(val) {
      switch (val) {
        case 1:
          return "未上架";
        case 2:
          return "审批中";
        case 3:
          return "已上架";
      }
    },
    closehandleShow() {
      this.itemsShow = false;
    },
    itemsSelectShow() {
      this.itemsShow = true;
    },
    handleCancel() {
      // 返回操作
      this.$router.go(-1);
    },
    // 获取选中货品
    getItemsData(val) {
      this.goodsItems = val;
      this.itemsShow = false;
      this.formData.itemCodes = [];
      val.forEach((item) => {
        this.formData.itemCodes.push(item.itemCode);
      });
    },
  },
};
</script>
<style lang="scss" scoped></style>
