<template>
  <div>
    <div class="page_header_title_box">
      设备列表
      <!-- <el-button class="mini_add_btn" @click="synchronizer()">同步设备订单</el-button> -->
      <el-button class="mini_add_btn" @click="addpuls(1)">+ 添加设备</el-button>
      <el-button class="mini_add_btn" @click="importdevicelist(1)"
        >导入设备</el-button
      >
      <el-button class="mini_add_btn" @click="importdevicelist(2)"
        >导入调整次数</el-button
      >
      <el-button class="mini_add_btn" @click="batchSetUp()">批量设置</el-button>
      <el-button class="mini_add_btn" @click="exportXLS()">导出设备</el-button>
      <el-button class="mini_add_btn" @click="batchEnableDisable(1)"
        >批量启用设备</el-button
      >
      <el-button class="mini_add_btn" @click="batchEnableDisable(0)"
        >批量禁用设备</el-button
      >
    </div>
    <div style="padding: 70px 20px 20px 20px">
      <div class="content_box">
        <div class="heading_box">
          <div class="search_for_box">
            <el-select
              size="mini"
              v-model="pageInfo.deviceCategoryId"
              placeholder="设备类型"
              style="width: 100px"
              clearable
            >
              <el-option
                v-for="(item, index) in devicetypeList"
                :key="index"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
            <el-select
              size="mini"
              v-model="pageInfo.activeStatus"
              placeholder="激活状态"
              style="width: 100px"
              clearable
            >
              <el-option label="已激活" :value="1"></el-option>
              <el-option label="未激活" :value="2"></el-option>
            </el-select>
            <el-select
              size="mini"
              v-model="pageInfo.openFlag"
              placeholder="设备状态"
              style="width: 100px"
              clearable
            >
              <el-option label="启用" :value="1"></el-option>
              <el-option label="禁用" :value="2"></el-option>
            </el-select>
            <el-autocomplete
              size="mini"
              style="width: 200px"
              v-model="distributorName"
              :fetch-suggestions="querySearchAsync"
              placeholder="请输入分销商名称"
              @select="handleSelect"
            ></el-autocomplete>
            <el-autocomplete
              size="mini"
              style="width: 200px"
              v-model="shopName"
              :fetch-suggestions="querySearchshopAsync"
              placeholder="请输入店铺名称"
              @select="handleshopSelect"
            ></el-autocomplete>
          </div>
          <div>
            <el-input
              placeholder="请输入内容"
              v-model="pageInfo.content"
              @keyup.enter.native="onSearch()"
            >
              <el-button
                slot="append"
                icon="el-icon-search"
                @click.prevent="onSearch()"
              ></el-button>
            </el-input>
          </div>
        </div>
        <el-table
          ref="multipleSelect"
          :data="tableData"
          v-loading="loading"
          @select="select"
          @select-all="selectAll"
          @selection-change="handleSelectionChange"
        >
          <el-table-column
            type="selection"
            width="50"
            align="center"
          ></el-table-column>
          <el-table-column
            label="设备ID"
            align="center"
            prop="deviceNo"
            width="120"
            fixed
          ></el-table-column>
          <el-table-column
            label="设备类型"
            align="center"
            prop="deviceCategoryName"
            width="120"
            fixed
          ></el-table-column>
          <el-table-column
            label="设备编码"
            align="center"
            prop="deviceCode"
            width="120"
            fixed
          ></el-table-column>
          <el-table-column align="center" label="激活状态" width="100">
            <template slot-scope="scope">
              {{ scope.row.activeStatus == 1 ? "已激活" : "未激活" }}
            </template>
          </el-table-column>
          <el-table-column align="center" label="在线状态" width="100">
            <template slot-scope="scope">
              <div
                :style="{
                  color: scope.row.onlineFlag == 1 ? '#29bf20' : '#9aa19a',
                }"
              >
                {{ scope.row.onlineFlag == 1 ? "在线" : "离线" }}
              </div>
            </template>
          </el-table-column>
          <el-table-column
            label="上次在线时间"
            align="center"
            prop="onlineTime"
            width="180"
          ></el-table-column>
          <el-table-column
            label="切膜次数"
            align="center"
            prop="frequency"
            width="120"
          ></el-table-column>
          <el-table-column
            label="vip到期时间"
            align="center"
            prop="vipEndTime"
            width="180"
          >
            <template slot-scope="scope">
              {{
                scope.row.vipDay != undefined && scope.row.vipDay > 0
                  ? scope.row.vipEndTime
                  : "-"
              }}
            </template>
          </el-table-column>
          <el-table-column align="center" label="设备状态" width="100">
            <template slot-scope="scope">
              {{ scope.row.openFlag == 1 ? "启用" : "禁用" }}
            </template>
          </el-table-column>
          <el-table-column
            label="关联分销商/ID"
            align="center"
            prop="saleName"
            width="220"
            show-overflow-tooltip
          >
            <template slot-scope="scope">
              {{
                scope.row.distributorName != undefined
                  ? scope.row.distributorName + "/" + scope.row.distributorId
                  : "-"
              }}
            </template>
          </el-table-column>
          <el-table-column
            label="店铺名称/联系人/手机号/地址"
            align="center"
            width="300"
            show-overflow-tooltip
          >
            <template slot-scope="scope">
              <div v-if="scope.row.shopName != undefined">
                <span v-if="scope.row.shopName">{{ scope.row.shopName }}</span>
                <span v-if="scope.row.name">/{{ scope.row.name }}</span>
                <span v-if="scope.row.phone">/{{ scope.row.phone }}</span>
                <span v-if="scope.row.address">/{{ scope.row.address }}</span>
              </div>
              <div v-else>-</div>
            </template>
          </el-table-column>
          <el-table-column
            label="操作"
            width="550"
            align="center"
            fixed="right"
          >
            <template slot-scope="scope">
              <el-button
                type="primary"
                @click="frequencyAdjustment(scope.row.deviceNo)"
                >次数管理</el-button
              >
              <el-button type="success" @click="viewLog(scope.row.deviceNo)"
                >查看日志</el-button
              >
              <el-button
                type="warning"
                :class="
                  scope.row.openFlag == 1
                    ? 'mini-delete-btn'
                    : 'mini-freeze-btn'
                "
                @click="openDevice(scope.row)"
              >
                {{ scope.row.openFlag == 1 ? "禁用" : "启用" }}</el-button
              >
              <el-button @click="addpuls(3, scope.row.id)">编辑</el-button>
              <el-button
                type="danger"
                v-if="scope.row.openFlag == 0"
                @click="handleDelete(scope.row)"
                >删除</el-button
              >
              <el-button type="danger" @click="resetDevice(scope.row)"
                >重置设备</el-button
              >
              <el-button type="primary" @click="syncDevicehronizer(scope.row)"
                >同步设备</el-button
              >
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          layout="sizes, prev, pager, next"
          :total="total"
          :page="pageInfo.page"
          :page-sizes="[10, 20, 50, 100, 300, 500]"
          @size-change="onSizeCHange"
          @current-change="onCurrentChange"
        >
        </el-pagination>
      </div>
    </div>
    <!-- 次数管理 -->
    <div class="cover" v-if="Ismanage"></div>
    <div class="goods_ewm" v-if="Ismanage">
      <div
        class="rl-margin-bottom-default rl-padding-top-xxxs rl-padding-bottom-xxxs rl-padding-left-xxxs rl-tl"
      >
        <div class="ewm_title">切膜次数管理</div>
      </div>
      <div class="shop_form_box" style="text-align: center">
        <div class="exchange_purchase_box">
          <el-form ref="form" label-width="80px">
            <el-form-item label="调整类型">
              <div class="return_goods_types_box">
                <div
                  @click="frequencyType = 1"
                  :class="
                    frequencyType == 1
                      ? 'purchase_types_box_item'
                      : 'return_goods_types_meu'
                  "
                >
                  切膜次数
                </div>
                <div
                  @click="frequencyType = 3"
                  :class="
                    frequencyType == 3
                      ? 'purchase_types_box_item'
                      : 'return_goods_types_meu'
                  "
                >
                  切膜天数
                </div>
              </div>
            </el-form-item>
            <el-form-item label="调整数量">
              <div style="float: left">
                <el-input
                  v-model="countOrDay"
                  size="mini"
                  style="width: 300px"
                  placeholder="请输入整数数字，正数为增加，负数位减少"
                  maxlength="40"
                >
                </el-input>
              </div>
            </el-form-item>
            <el-form-item label="调整原因">
              <div style="float: left">
                <el-input
                  v-model="remark"
                  size="mini"
                  style="width: 300px"
                  placeholder="请输入调整原因"
                  maxlength="40"
                >
                </el-input>
              </div>
            </el-form-item>
          </el-form>
        </div>
      </div>
      <el-button type="primary" @click="submitTo()">确认调整</el-button>
      <el-button @click="Ismanage = false">返回</el-button>
    </div>
    <!-- 查看日志 -->
    <el-dialog
      :visible="lookLogShow"
      width="90%"
      title="查看日志"
      :before-close="closeLog"
    >
      <div
        style="
          margin-bottom: 20px;
          display: flex;
          justify-content: space-between;
        "
      >
        <div>
          <el-date-picker
            size="mini"
            v-model="logPageInfo.time"
            style="width: 330px"
            type="datetimerange"
            value-format="timestamp"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            :default-time="['00:00:00', '23:59:59']"
          ></el-date-picker>
          <el-select
            size="mini"
            v-model="logPageInfo.frequencyType"
            placeholder="业务类型"
            style="width: 100px; margin-left: 5px"
            clearable
          >
            <el-option label="购买次数" :value="2"></el-option>
            <el-option label="膜码兑换" :value="5"></el-option>
            <el-option label="数据调整" :value="1"></el-option>
            <el-option label="调整天数" :value="3"></el-option>
            <el-option label="购买天数" :value="4"></el-option>
            <el-option label="切膜消耗" :value="6"></el-option>
            <el-option label="赠送次数" :value="7"></el-option>
          </el-select>
          <el-select
            size="mini"
            @change="selectcutType"
            v-model="logPageInfo.cutType"
            placeholder="切膜类型"
            style="width: 100px; margin-left: 5px"
            clearable
          >
            <el-option label="普通切膜" :value="1"></el-option>
            <el-option label="优惠切膜" :value="2"></el-option>
          </el-select>
        </div>
        <div style="display: flex">
          <el-select
            size="mini"
            v-model="logPageInfo.contentType"
            placeholder="搜索内容类型"
            style="width: 180px; margin-right: 5px"
            clearable
          >
            <el-option label="业务单号" :value="1"></el-option>
            <el-option label="兑换膜码" :value="2"></el-option>
            <el-option label="手机号" :value="3"></el-option>
            <el-option label="设备号" :value="4"></el-option>
          </el-select>
          <el-input
            placeholder="请输入内容"
            v-model="logPageInfo.content"
            @keyup.enter.native="searchLos()"
          >
            <el-button
              slot="append"
              icon="el-icon-search"
              @click.prevent="searchLos()"
            ></el-button>
          </el-input>
        </div>
      </div>
      <el-table
        :data="lookLogs"
        max-height="600"
        style="width: 100%"
        header-row-class-name="header-row"
        class="tableCenter"
        v-loading="lookLogLoading"
      >
        <el-table-column
          align="center"
          label="设备号"
          width="90"
          prop="deviceNo"
        ></el-table-column>
        <el-table-column
          label="业务类型"
          align="center"
          prop="frequencyType"
          width="100"
        >
          <template slot-scope="scope">
            {{ frequencyName(scope.row.frequencyType, scope.row.cutType) }}
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          label="业务单号"
          width="140"
          prop="businessId"
        ></el-table-column>
        <el-table-column
          align="center"
          label="用户名称"
          width="140"
          prop="customerName"
        ></el-table-column>
        <el-table-column
          align="center"
          label="用户手机号"
          width="140"
          prop="phone"
        ></el-table-column>
        <el-table-column
          align="center"
          label="用户手机型号"
          width="140"
          prop="phoneModel"
        ></el-table-column>
        <el-table-column
          align="center"
          label="类型名称"
          width="140"
          prop="classifyname"
        ></el-table-column>
        <el-table-column
          align="center"
          label="品牌"
          width="140"
          prop="brandname"
        ></el-table-column>
        <el-table-column
          align="center"
          label="切膜手机型号"
          width="140"
          prop="seriesname"
        ></el-table-column>

        <el-table-column
          align="center"
          label="变化数额"
          width="90"
          prop="countOrDay"
        >
          <template slot-scope="scope">
            {{ scope.row.frequencyType == 6 ? "-" : ""
            }}{{ scope.row.countOrDay }}
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          label="操作时间"
          prop="createTime"
        ></el-table-column>
        <el-table-column
          align="center"
          label="备注"
          prop="remark"
        ></el-table-column>
      </el-table>
      <el-pagination
        :total="logTotal"
        :page="logPageInfo.page"
        @size-change="onlosSizeCHange"
        @current-change="onlosCurrentChange"
      >
      </el-pagination>
    </el-dialog>
    <div class="cover" v-if="Issetup"></div>
    <div class="goods_ewm" v-if="Issetup">
      <div
        class="rl-margin-bottom-default rl-padding-top-xxxs rl-padding-bottom-xxxs rl-padding-left-xxxs rl-tl"
      >
        <div class="ewm_title">批量设置</div>
      </div>
      <div class="shop_form_box" style="text-align: center">
        <div class="exchange_purchase_set_box">
          <el-form ref="form" label-width="80px">
            <el-form-item label="切割模式">
              <div style="float: left">
                <el-radio-group v-model="cutCoupon">
                  <el-radio :label="0">普通切割</el-radio>
                  <el-radio :label="1">扫码切割</el-radio>
                  <el-radio :label="2">普通+扫码切割</el-radio>
                </el-radio-group>
              </div>
            </el-form-item>
            <el-form-item label="扫码链接" v-if="cutCoupon != 0">
              <div style="float: left">
                <el-input
                  v-model="couponUrl"
                  size="mini"
                  style="width: 300px"
                  placeholder="请输入"
                >
                </el-input>
              </div>
            </el-form-item>
          </el-form>
        </div>
      </div>
      <div class="button_box">
        <div class="exchange_bit" @click="confirmExchange()">确认设置</div>
        <div class="return_bit" @click="Issetup = false">返回</div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import { setArr2, parseTime } from "@/utils/common.js";
import url from "@/api/allUrl";
export default {
  name: "deviceList",
  data() {
    return {
      logTotal: 0,
      distributorShow: false,
      distributorData: [],
      logPageInfo: {
        page: 1,
        size: 10,
        distributorId: undefined,
        content: "",
        contentType: undefined, //1 业务单号 2 兑换膜码 3 手机号 4 设备号
        cutType: undefined, //切膜类型：1 普通切膜 2 优惠切膜（次数变更类型为3时有值）
        deviceNo: "", //设备号
        frequencyType: undefined, //1 调整次数 2 购买次数 3 调整天数 3 购买天数 4 膜码兑换 5 切膜消耗
        endTime: "", //结束时间
        startTime: "", //开始时间
      },
      lookLogs: [],
      loading: false,
      lookLogLoading: false,
      lookLogShow: false,
      Ismanage: false,
      total: 1,
      pageInfo: {
        page: 1,
        size: 10,
        deviceCategoryId: "",
        activeStatus: undefined,
        content: "",
        distributorId: undefined,
        openFlag: undefined,
        shopId: undefined,
      },
      tableData: [],
      shopName: "",
      distributorName: "",
      devicetypeList: [],
      deviceNo: "",
      countOrDay: 1,
      frequencyType: 0,
      remark: "",
      isSelect: false,
      deviceIds: [],
      Issetup: false,
      cutCoupon: 0,
      couponUrl: "",
    };
  },
  activated() {
    this.loading = true;
    this.getDeviceList();
    //获取设备类型
    this.getDevicetypeList();
  },
  methods: {
    batchEnableDisable(openFlag) {
      if (this.deviceIds.length == 0) {
        this.$message({
          type: "info",
          message: "请选择设备",
        });
      } else {
        var mess = "启用该设备，是否继续？";
        if (openFlag == 0) {
          mess = "此操作将禁用该设备，是否继续？";
        }
        this.$confirm(mess, "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
          center: true,
        })
          .then((_) => {
            console.log(_);
            this.loading = true;
            this.$api
              .put(this, url.openDevicedetails, {
                ids: this.deviceIds,
                openFlag: openFlag,
              })
              .then((res) => {
                if (res.success) {
                  this.loading = false;
                  this.$message.success({
                    message: "操作成功",
                    duration: 3 * 1000,
                  });
                  this.loading = true;
                  this.getDeviceList();
                }
              });
          })
          .catch((_) => {
            console.log(_);
            this.loading = false;
            this.$message({
              type: "info",
              message: "已取消",
            });
          });
      }
    },
    synchronizer() {
      if (this.deviceIds.length == 0) {
        this.$message({
          type: "info",
          message: "请选择设备",
        });
      } else {
        this.$confirm("是否同步选择的设备订单？", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
          center: true,
        })
          .then((_) => {
            console.log(_);
            this.$api
              .post(this, url.synchronizer, {
                ids: this.deviceIds,
              })
              .then((res) => {
                if (res.success) {
                  this.$message.success({
                    message: "同步成功",
                    duration: 3 * 1000,
                  });
                }
              });
          })
          .catch((_) => {
            console.log(_);
            this.$message({
              type: "info",
              message: "已取消",
            });
          });
      }
    },
    selectcutType() {
      if (this.logPageInfo.cutType != "") {
        this.logPageInfo.frequencyType = 6;
      } else {
        this.logPageInfo.frequencyType = "";
      }
    },
    confirmExchange() {
      if (this.cutCoupon == 0) {
        this.couponUrl = "";
      }
      this.$api
        .put(this, url.setMode, {
          cutCoupon: this.cutCoupon,
          couponUrl: this.couponUrl,
          ids: this.deviceIds,
        })
        .then((res) => {
          if (res.success) {
            this.$message.success({
              message: "设置成功",
              duration: 3 * 1000,
            });
            this.loading = true;
            this.deviceIds = [];
            this.getDeviceList();
            this.Issetup = false;
            this.cutCoupon = 0;
          }
        });
    },
    batchSetUp() {
      if (this.deviceIds.length == 0) {
        this.$message({
          type: "info",
          message: "请选择设备",
        });
      } else {
        this.cutCoupon = 0;
        this.couponUrl = "";
        this.Issetup = true;
      }
    },
    exportXLS() {
      var tenantUrl = "";
      tenantUrl = process.env.VUE_APP_API_URL + url.exportDevice;
      axios({
        method: "post",
        url: tenantUrl,
        data: {
          activeStatus: this.pageInfo.activeStatus,
          content: this.pageInfo.content,
          deviceCategoryId: this.pageInfo.deviceCategoryId,
          distributorId: this.pageInfo.distributorId,
          endTime: this.pageInfo.endTime,
          startTime: this.pageInfo.startTime,
          openFlag: this.pageInfo.openFlag,
          shopId: this.pageInfo.shopId,
        },
        responseType: "arraybuffer",
        headers: {
          "Content-Type": "application/json",
          token: localStorage.getItem("token"),
          tenantNo: 100,
          value: 100,
        },
      }).then((res) => {
        const content = res.data;
        let blob = new Blob([content], {
          type: "application/ms-excel",
        });
        let url = window.URL.createObjectURL(blob);
        if ("download" in document.createElement("a")) {
          let link = document.createElement("a");
          link.style.display = "none";
          link.href = url;
          link.setAttribute("download", "设备列表.xls");
          document.body.appendChild(link);
          link.click();
        } else {
          navigator.msSaveBlob(blob, "设备列表.xls");
        }
      });
    },
    select(selection, row) {
      this.isSelect = true;
      // 单选时调用
      let d = false;
      for (let i = 0; i < this.deviceIds.length; i++) {
        if (this.deviceIds[i] === row.id) {
          this.deviceIds.splice(i, 1);
          d = true;
          break;
        }
      }
      if (!d) {
        this.deviceIds.push(row.id);
        this.deviceIds = setArr2(this.deviceIds);
      }
    },
    selectAll(selection) {
      this.isSelect = true;
      // 全选时调用
      if (selection.length === 0) {
        this.tableData.forEach((row) => {
          for (let i = 0; i < this.deviceIds.length; i++) {
            if (this.deviceIds[i] === row.id) {
              this.deviceIds.splice(i, 1);
              break;
            }
          }
        });
      } else {
        selection.forEach((item) => {
          this.deviceIds.push(item.id);
        });
        this.deviceIds = setArr2(this.deviceIds);
      }
    },
    handleSelectionChange(val) {
      // 当切换页面时的作用
      if (val.length === 0 && this.deviceIds.length !== 0 && !this.isSelect) {
        this.deviceIds.forEach((row1) => {
          this.tableData.forEach((row2) => {
            if (row1 === row2.id) {
              this.$refs.multipleSelect.toggleRowSelection(row2);
            }
          });
        });
        this.isSelect = true;
      }
    },
    //搜索日志
    searchLos() {
      if (this.logPageInfo.frequencyType != 6) {
        this.logPageInfo.cutType = undefined;
      }
      this.logPageInfo.page = 1;
      this.lookLogLoading = true;
      this.getDevicevlog();
    },
    //查看日志
    viewLog(deviceNo) {
      this.lookLogShow = true;
      this.lookLogLoading = true;
      this.logPageInfo = {
        page: 1,
        size: 10,
        distributorId: undefined,
        content: "",
        contentType: undefined, //1 业务单号 2 兑换膜码 3 手机号 4 设备号
        cutType: undefined, //切膜类型：1 普通切膜 2 优惠切膜（次数变更类型为3时有值）
        frequencyType: undefined, //1 调整次数 2 购买次数 3 调整天数 3 购买天数 4 膜码兑换 5 切膜消耗
        endTime: "", //结束时间
        startTime: "", //开始时间
      };
      this.logPageInfo.deviceNo = deviceNo;
      this.getDevicevlog();
    },
    getDevicevlog() {
      this.$api.get(this, url.getDevicevlog, this.logPageInfo).then((res) => {
        if (res.success) {
          this.lookLogs = res.data.list;
          this.logTotal = res.data.total;
        }
        this.lookLogLoading = false;
      });
    },
    frequencyName(v, cutType) {
      // 1 调整次数 2 购买次数 3 调整天数 4 购买天数 5 膜码兑换 6 切膜消耗 7 赠送次数
      switch (v) {
        case 1:
          return "调整次数";
        case 2:
          return "购买次数";
        case 3:
          return "调整天数";
        case 4:
          return "购买天数";
        case 5:
          return "膜码兑换";
        case 6:
          if (cutType == 1) {
            return "普通切膜";
          } else {
            return "优惠切膜";
          }
        case 7:
          return "赠送次数";
        default:
          return "-";
      }
    },
    onlosSizeCHange(val) {
      // 分页方法
      this.logPageInfo.size = val;
      this.lookLogLoading = true;
      this.getDevicevlog();
    },
    onlosCurrentChange(val) {
      // 分页方法
      this.logPageInfo.page = val;
      this.lookLogLoading = true;
      this.getDevicevlog();
    },
    //确认调整
    submitTo() {
      if (/^[-+]?\d+(\.\d+)?$/.test(this.countOrDay)) {
        this.$confirm("确定调整该设备？", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
          center: true,
        })
          .then((_) => {
            console.log(_);
            this.$api
              .put(this, url.adjustmentDevice, {
                countOrDay: this.countOrDay,
                deviceNo: this.deviceNo,
                frequencyType: this.frequencyType,
                remark: this.remark,
              })
              .then((res) => {
                if (res.success) {
                  this.$message.success({
                    message: "操作成功",
                    duration: 3 * 1000,
                  });
                  this.Ismanage = false;
                  this.loading = true;
                  this.getDeviceList();
                }
              });
          })
          .catch((_) => {
            console.log(_);
            this.$message({
              type: "info",
              message: "已取消",
            });
          });
      } else {
        this.$message({
          type: "info",
          message: "设备次数请输入数字",
        });
      }
    },
    //调整次数
    frequencyAdjustment(deviceNo) {
      this.countOrDay = "";
      this.frequencyType = 1;
      this.remark = "";
      this.deviceNo = deviceNo;
      this.Ismanage = true;
    },
    syncDevicehronizer(row) {
      this.$confirm("是否同步该设备？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
        center: true,
      })
        .then((_) => {
          console.log(_);
          this.$api
            .post(this, url.syncDevicehronizer, {
              ids: [row.id],
            })
            .then((res) => {
              if (res.success) {
                this.$message.success({
                  message: "同步成功",
                  duration: 3 * 1000,
                });
                this.loading = true;
                this.getDeviceList();
              }
            });
        })
        .catch((_) => {
          console.log(_);
          this.$message({
            type: "info",
            message: "已取消",
          });
        });
    },
    //重置设备
    resetDevice(row) {
      this.$confirm("是否重置该设备？重置后数据清零，请谨慎处理", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
        center: true,
      })
        .then((_) => {
          console.log(_);
          this.$api
            .put(this, url.resetDevice, {
              id: row.id,
            })
            .then((res) => {
              if (res.success) {
                this.$message.success({
                  message: "操作成功",
                  duration: 3 * 1000,
                });
                this.loading = true;
                this.getDeviceList();
              }
            });
        })
        .catch((_) => {
          console.log(_);
          this.$message({
            type: "info",
            message: "已取消",
          });
        });
    },
    //启用禁用设备
    openDevice(row) {
      var mess = "启用该设备，是否继续？";
      var openFlag = 1;
      if (row.openFlag == 1) {
        mess = "此操作将禁用该设备，是否继续？";
        openFlag = 0;
      }
      this.$confirm(mess, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
        center: true,
      })
        .then((_) => {
          this.loading = true;
          console.log(_);
          this.$api
            .put(this, url.openDevicedetails, {
              ids: [row.id],
              openFlag: openFlag,
            })
            .then((res) => {
              if (res.success) {
                this.loading = false;
                this.$message.success({
                  message: "操作成功",
                  duration: 3 * 1000,
                });
                this.loading = true;
                this.getDeviceList();
              }
            });
        })
        .catch((_) => {
          this.loading = false;
          console.log(_);
          this.$message({
            type: "info",
            message: "已取消",
          });
        });
    },
    //删除设备
    handleDelete(row) {
      this.$confirm("此操作将永久删除该设备，是否继续？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
        center: true,
      })
        .then((_) => {
          console.log(_);
          this.$api
            .delete(this, url.deleteDevicedetails, { id: row.id })
            .then((res) => {
              if (res.success) {
                this.$message.success({
                  message: "删除成功",
                  duration: 3 * 1000,
                });
                this.pageInfo.page = 1;
                this.pageInfo.content = "";
                this.loading = true;
                this.getDeviceList();
              }
            });
        })
        .catch((_) => {
          console.log(_);
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    //搜索设备列表
    onSearch() {
      this.loading = true;
      this.pageInfo.page = 1;
      this.getDeviceList();
    },
    //获取设备列表
    getDeviceList() {
      this.$api.get(this, url.getDeviceList, this.pageInfo).then((res) => {
        if (res.success) {
          this.tableData = res.data.list;
          this.total = res.data.total;
          setTimeout(() => {
            this.deviceIds.forEach((row1) => {
              this.tableData.forEach((row2) => {
                if (row1 === row2.id) {
                  this.$refs.multipleSelect.toggleRowSelection(row2);
                }
              });
            });
          }, 100);
        }
        this.loading = false;
      });
    },
    disSubmit(msg) {
      this.distributorData = msg;
      this.distributorShow = false;
    },
    closeLog() {
      this.lookLogShow = false;
    },
    addpuls(checkMsg, id) {
      var query = {};
      if (id) {
        query = {
          checkMsg: checkMsg,
          id: id,
        };
      } else {
        query = {
          checkMsg: checkMsg,
        };
      }
      this.$router.push({
        name: "add_device",
        query: query,
      });
    },
    importdevicelist(index) {
      var query = { checkMsg: index };
      this.$router.push({ name: "import_devicelist", query: query });
    },
    onSizeCHange(val) {
      // 分页方法
      this.pageInfo.size = val;
      this.loading = true;
      this.getDeviceList();
    },

    onCurrentChange(val) {
      // 分页方法
      this.pageInfo.page = val;
      this.loading = true;
      this.getDeviceList();
    },
    handleSelect(item) {
      this.pageInfo.distributorId = item.id;
    },
    handleshopSelect(item) {
      this.pageInfo.shopId = item.id;
    },
    querySearchshopAsync(queryString, cb) {
      let pageInfo = {
        content: queryString,
        openFlag: 1,
        page: 1,
        size: 10,
      };
      this.$api.get(this, url.shopList, pageInfo).then((res) => {
        if (res.success) {
          var restaurants = [];
          res.data.list.forEach((item) => {
            restaurants.push({
              value: item.shopName,
              id: item.id,
            });
          });
        }
        cb(restaurants);
      });
    },
    querySearchAsync(queryString, cb) {
      let pageInfo = {
        page: 1,
        size: 10,
        freezeStatus: 1,
        contentType: 1,
        content: queryString,
      };

      this.$api.get(this, url.getDistributorNPoList, pageInfo).then((res) => {
        if (res.success) {
          var restaurants = [];
          res.data.list.forEach((item) => {
            restaurants.push({
              value: item.name,
              id: item.id,
            });
          });
        }
        cb(restaurants);
      });
    },
    // 获取设备类型
    getDevicetypeList() {
      this.$api
        .get(this, url.getDevicetypeList, {
          page: 1,
          size: 100,
        })
        .then((res) => {
          this.devicetypeList = res.data.list;
        });
    },
  },
  watch: {
    distributorName: {
      handler(val) {
        if (val == "") {
          this.pageInfo.distributorId = undefined;
        }
      },
      deep: true,
    },
    shopName: {
      handler(val) {
        if (val == "") {
          this.pageInfo.shopId = undefined;
        }
      },
      deep: true,
    },
    "logPageInfo.time": {
      handler(val, oldVal) {
        if (val) {
          this.logPageInfo.startTime = parseTime(val[0]);
          this.logPageInfo.endTime = parseTime(val[1]);
        } else {
          this.logPageInfo.startTime = undefined;
          this.logPageInfo.endTime = undefined;
        }
        if (val !== oldVal) {
          this.logPageInfo.page = 1;
        }
      },
      deep: true,
    },
  },
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.cover {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #000;
  z-index: 99;
  opacity: 0.5;
}

.purchase_types_box_item {
  width: 120px;
  height: 40px;
  line-height: 40px;
  border-radius: 10px;
  border: 2px solid #ff7500;
  color: #ff7500;
  font-size: 14px;
  text-align: center;
  box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.08);
  font-family: Roboto;
  margin-right: 10px;
  cursor: pointer;
}

.goods_ewm {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: block;
  width: 660px;
  height: 400px;
  z-index: 99;
  margin: auto;
  background: #fff;
  text-align: center;
  border-radius: 10px;

  .shop_form_box {
    width: 700px;
    height: auto;
    margin: 0 auto;
    margin-top: 20px;
    overflow: hidden;
    overflow-y: auto;
    text-align: left;
    margin-bottom: 10px;

    .exchange_purchase_box {
      width: 698px;
      height: auto;
      border-radius: 10px;
      padding: 30px;
      box-sizing: border-box;
    }

    .exchange_purchase_set_box {
      width: 550px;
      height: auto;
      margin: 0 auto;
      border-radius: 10px;
      padding: 30px;
      box-sizing: border-box;
      margin-bottom: 60px;
    }

    .types_of_illustrate_box {
      width: 550px;
      height: auto;
      border-radius: 15px;
      text-align: left;
      font-family: Roboto;
      padding: 10px;
      box-sizing: border-box;
      margin-top: 10px;
      color: rgba(148, 146, 146, 1);
      font-size: 14px;
      font-family: SourceHanSansSC-regular;

      .types_of_illustrate_title {
        color: #ff7500;
        font-size: 14px;
        text-align: left;
        font-family: SourceHanSansSC-regular;
        line-height: 16px;
      }
    }

    .return_goods_types_box {
      display: flex;

      .return_goods_types_meu {
        width: 120px;
        height: 40px;
        line-height: 40px;
        border-radius: 10px;
        background-color: rgba(255, 255, 255, 1);
        color: rgba(16, 16, 16, 1);
        font-size: 14px;
        text-align: center;
        box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.08);
        font-family: Roboto;
        border: 2px solid rgba(38, 37, 37, 0.09);
        margin-right: 10px;
        cursor: pointer;
      }
    }
  }
}

.ewm_title {
  color: #101010;
  font-size: 22px;
  text-align: center;
  font-family: SourceHanSansSC-regular;
  padding-top: 30px;
}

.button_box {
  margin: 0 auto;
  display: flex;
  /* 居中显示 */
  align-items: center;
  justify-content: center;

  .exchange_bit {
    width: 100px;
    height: 32px;
    line-height: 32px;
    border-radius: 50px 50px 50px 50px;
    background-color: rgba(252, 126, 27, 1);
    text-align: center;
    color: #ffffff;
    cursor: pointer;
  }

  .return_bit {
    width: 100px;
    height: 32px;
    line-height: 32px;
    border-radius: 50px 50px 50px 50px;
    background-color: rgba(221, 224, 231, 1);
    text-align: center;
    color: #101010;
    margin-left: 10px;
    cursor: pointer;
  }
}
</style>
