<template>
  <div style="width: 90%">
    <el-form
      :model="formData"
      :rules="rules"
      label-width="120px"
      label-position="right"
      ref="formData"
    >
      <!-- <el-form-item label="材料总分类" prop="materialRootCategoryId">
        <el-select size="mini" placeholder="材料分类" v-model="formData.materialRootCategoryId" clearable>
          <el-option label="启用" :value="1"></el-option>
          <el-option label="禁用" :value="2"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="材料子分类" prop="materialCategoryId">
        <el-select size="mini" placeholder="材料分类" clearable v-memo="formData.materialCategoryId">
          <el-option label="启用" :value="1"></el-option>
          <el-option label="禁用" :value="2"></el-option>
        </el-select>
      </el-form-item> -->
      <el-form-item label="材料分类" prop="materialCategoryId">
        <el-cascader
          :options="categorylist"
          :show-all-levels="false"
          v-model="formData.materialCategoryId"
          :props="{ label: 'name', value: 'id' }"
          @change="selectcategorylist"
        ></el-cascader>
      </el-form-item>
      <el-form-item
        label="材料"
        prop="materialId"
        v-if="formData.materialCategoryId != ''"
      >
        <el-select
          size="mini"
          placeholder="材料分类"
          clearable
          v-model="formData.materialId"
        >
          <el-option
            :value="item.id"
            :label="item.name"
            v-for="(item, index) in materialList"
            :key="index"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="型号分类" prop="modelCategoryId">
        <el-cascader
          :options="modelTypelist"
          :show-all-levels="false"
          v-model="formData.modelCategoryId"
          :props="{ label: 'name', value: 'id' }"
          @change="selectmodelCategoryId"
        ></el-cascader>
      </el-form-item>
      <el-form-item
        label="型号"
        prop="modelId"
        v-if="formData.modelCategoryId != ''"
      >
        <el-select
          size="mini"
          placeholder="材料分类"
          clearable
          v-model="formData.modelId"
        >
          <el-option
            :value="item.id"
            :label="item.nameCn"
            v-for="(item, index) in getModellist"
            :key="index"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="第三方编码" prop="thirdPartyFactoryCode">
        <el-input
          placeholder="请输入第三方工厂对应编码"
          v-model="formData.thirdPartyFactoryCode"
          maxlength="100"
        />
      </el-form-item>
      <el-form-item label="外框图" prop="borderImage">
        <el-upload
          :action="action"
          :headers="headers"
          list-type="picture-card"
          :limit="1"
          :on-exceed="handleExceed"
          :on-remove="handleRemove"
          :before-upload="beforeUpload"
          :file-list="fileList"
        >
          <i slot="default" class="el-icon-plus"></i>
        </el-upload>
      </el-form-item>
      <el-form-item label="底图" prop="backgroundImage">
        <el-upload
          :action="action"
          :headers="headers"
          list-type="picture-card"
          :limit="1"
          :on-exceed="handleExceed1"
          :on-remove="handleRemove1"
          :before-upload="beforeUpload1"
          :file-list="fileList1"
        >
          <i slot="default" class="el-icon-plus"></i>
        </el-upload>
      </el-form-item>
      <el-form-item label="颜色">
        <el-color-picker v-model="formData.color"></el-color-picker>
      </el-form-item>
      <el-form-item label="体积(mm)">
        <div class="volume_box">
          长
          <el-input type="number" v-model="formData.length" maxlength="10" /> 宽
          <el-input type="number" v-model="formData.width" maxlength="10" /> 高
          <el-input type="number" v-model="formData.height" maxlength="10" />
        </div>
      </el-form-item>
      <el-form-item label="重量(g)" prop="weight">
        <div class="volume_box">
          <el-input v-model="formData.weight" placeholder="请输入商品重量" />
        </div>
      </el-form-item>
      <el-form-item label="裁切尺寸(mm)">
        <div class="volume_box">
          上 <el-input type="number" v-model="formData.top" maxlength="10" /> 下
          <el-input type="number" v-model="formData.bottom" maxlength="10" /> 左
          <el-input type="number" v-model="formData.left" maxlength="10" /> 右
          <el-input type="number" v-model="formData.right" maxlength="10" />
        </div>
      </el-form-item>
      <el-form-item label="状态">
        <el-radio-group v-model="formData.status" size="mini">
          <el-radio :label="1">启用</el-radio>
          <el-radio :label="0">禁用</el-radio>
        </el-radio-group>
      </el-form-item>
    </el-form>
    <div class="button_box">
      <div class="exchange_bit" @click="submitData()">保存</div>
      <!-- <div class="return_bit">删除</div> -->
    </div>
  </div>
</template>
<script src="https://gosspublic.alicdn.com/aliyun-oss-sdk-4.4.4.min.js"></script>
<script>
import api from "@/api/allUrl";
import { monthDay } from "@/utils/common";
export default {
  props: ["blockDiagramDetails"],
  name: "editImg",
  data() {
    return {
      action:
        process.env.VUE_APP_API_URL + "/device/system/v1/web/admin/oss/sts",
      headers: {
        Accept: "application/json",
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        Platform: "GF60004",
        Version: "1.0.0",
        token: localStorage.getItem("token"),
        "Cache-Control": "no-cache",
        Pragma: "no-cache",
      },
      fileList: [],
      fileList1: [],
      formData: {
        backgroundImage: "",
        borderImage: "",
        bottom: "",
        color: "",
        height: "",
        left: "",
        length: "",
        materialCategoryId: "",
        materialId: "",
        materialRootCategoryId: "",
        modelCategoryId: "",
        modelId: "",
        right: "",
        status: 1,
        thirdPartyFactoryCode: "",
        top: "",
        weight: "",
        width: "",
      },
      categorylist: [],
      materialList: [],
      modelTypelist: [],
      getModellist: [],
      rules: {
        backgroundImage: [
          { required: true, message: "请上传底图", trigger: "blur" },
        ],
        borderImage: [
          { required: true, message: "请上传外框图", trigger: "blur" },
        ],
        materialCategoryId: [
          { required: true, message: "请选择材料分类", trigger: "blur" },
        ],
        materialId: [
          { required: true, message: "请选择材料", trigger: "blur" },
        ],
        materialRootCategoryId: [
          { required: true, message: "请选择材料总分类", trigger: "blur" },
        ],
        modelCategoryId: [
          { required: true, message: "请选择型号分类", trigger: "blur" },
        ],
        modelId: [{ required: true, message: "请选择型号", trigger: "blur" }],
        weight: [{ required: true, message: "请输入重量", trigger: "blur" }],
      },
    };
  },
  watch: {
    blockDiagramDetails: {
      //请求的分销商数据变化时
      handler() {
        if (this.blockDiagramDetails.id != undefined) {
          this.formData = this.blockDiagramDetails;
          this.fileList = [
            { name: "food.jpeg", url: this.formData.borderImage },
          ];
          this.fileList1 = [
            { name: "food.jpeg", url: this.formData.backgroundImage },
          ];
          this.formData.materialCategoryId = [
            this.formData.materialCategory.id,
            this.formData.materialCategory.children[0].parentId,
            this.formData.materialCategory.children[0].id,
          ];
          this.formData.materialId = this.formData.material.id;
          this.formData.modelCategoryId = [
            this.formData.modelCategory.id,
            this.formData.modelCategory.children[0].parentId,
            this.formData.modelCategory.children[0].id,
          ];
          this.formData.modelId = this.formData.model.id;
          this.selectmodelCategoryId(this.formData.modelId);
        } else {
          this.fileList = [];
          this.fileList1 = [];
          this.formData = {
            backgroundImage: "",
            borderImage: "",
            bottom: "",
            color: "",
            height: "",
            left: "",
            length: "",
            materialCategoryId: "",
            materialId: "",
            materialRootCategoryId: "",
            modelCategoryId: "",
            modelId: "",
            right: "",
            status: 1,
            thirdPartyFactoryCode: "",
            top: "",
            weight: "",
            width: "",
          };
        }
      },
      deep: true,
    },
  },
  activated() {
    this.getMaterialCategorylist();
    this.getMaterialList();
    this.getModel_Typelist();
  },
  methods: {
    submitData() {
      this.$refs["formData"].validate((valid) => {
        if (valid) {
          if (this.formData.length == "") {
            this.$message({
              message: "请输入长",
            });
          } else if (this.formData.width == "") {
            this.$message({
              message: "请输入宽",
            });
          } else if (this.formData.height == "") {
            this.$message({
              message: "请输入高",
            });
          } else if (this.formData.top == "") {
            this.$message({
              message: "请输入上边距",
            });
          } else if (this.formData.bottom == "") {
            this.$message({
              message: "请输入下边距",
            });
          } else if (this.formData.left == "") {
            this.$message({
              message: "请输入左边距",
            });
          } else if (this.formData.right == "") {
            this.$message({
              message: "请输入右边距",
            });
          } else {
            if (this.formData.id != undefined) {
              this.$api
                .put(this, api.updateBorderimageslist, {
                  backgroundImage: this.formData.backgroundImage,
                  borderImage: this.formData.borderImage,
                  bottom: this.formData.bottom,
                  color: this.formData.color,
                  height: this.formData.height,
                  left: this.formData.left,
                  length: this.formData.length,
                  materialCategoryId:
                    this.formData.materialCategoryId[
                      this.formData.materialCategoryId.length - 1
                    ],
                  materialId: this.formData.materialId,
                  materialRootCategoryId: this.formData.materialCategoryId[0],
                  modelCategoryId:
                    this.formData.modelCategoryId[
                      this.formData.modelCategoryId.length - 1
                    ],
                  modelId: this.formData.modelId,
                  right: this.formData.right,
                  id: this.formData.id,
                  status: this.formData.status,
                  thirdPartyFactoryCode: this.formData.thirdPartyFactoryCode,
                  top: this.formData.top,
                  weight: this.formData.weight,
                  width: this.formData.width,
                })
                .then((res) => {
                  if (res.success) {
                    this.$message({
                      message: "修改成功",
                    });
                    this.$emit("handleClose");
                  }
                });
            } else {
              this.$api
                .post(this, api.addBorderimageslist, {
                  backgroundImage: this.formData.backgroundImage,
                  borderImage: this.formData.borderImage,
                  bottom: this.formData.bottom,
                  color: this.formData.color,
                  height: this.formData.height,
                  left: this.formData.left,
                  length: this.formData.length,
                  materialCategoryId:
                    this.formData.materialCategoryId[
                      this.formData.materialCategoryId.length - 1
                    ],
                  materialId: this.formData.materialId,
                  materialRootCategoryId: this.formData.materialCategoryId[0],
                  modelCategoryId:
                    this.formData.modelCategoryId[
                      this.formData.modelCategoryId.length - 1
                    ],
                  modelId: this.formData.modelId,
                  right: this.formData.right,
                  status: this.formData.status,
                  thirdPartyFactoryCode: this.formData.thirdPartyFactoryCode,
                  top: this.formData.top,
                  weight: this.formData.weight,
                  width: this.formData.width,
                })
                .then((res) => {
                  if (res.success) {
                    this.$message({
                      message: "新增成功",
                    });
                    this.$emit("handleClose");
                  }
                });
            }
          }
        }
      });
    },
    getModel_Typelist() {
      this.$api.get(this, api.getModelTypelist).then((res) => {
        if (res.success) {
          this.modelTypelist = res.data;
        }
      });
    },
    selectmodelCategoryId(val) {
      this.getModel_list(val[val.length - 1]);
    },
    getModel_list(categoryId) {
      this.$api
        .get(this, api.getModellist, {
          page: 1,
          size: 100,
          status: 0,
          categoryId: categoryId,
        })
        .then((res) => {
          if (res.success) {
            this.getModellist = res.data.list;
          }
        });
    },
    selectcategorylist(val) {
      this.getMaterialList(val[val.length - 1]);
    },
    getMaterialList(categoryId) {
      this.$api
        .get(this, api.getMaterialList, {
          page: 1,
          size: 100,
          status: 0,
          categoryId: categoryId,
        })
        .then((res) => {
          if (res.success) {
            this.materialList = res.data.list;
          }
        });
    },
    getMaterialCategorylist() {
      this.$api.get(this, api.getMaterialCategorylist).then((res) => {
        if (res.success) {
          this.categorylist = res.data;
        }
      });
    },
    beforeUpload(file) {
      if (
        file.type != "image/jpeg" &&
        file.type != "image/bmp" &&
        file.type != "image/jpg" &&
        file.type != "image/jpeg" &&
        file.type != "image/png" &&
        file.type != "image/gif"
      ) {
        this.$message.error("上传图片只能是bmp、jpg、jpeg、png、gif格式!");
        return false;
      }
      const isLt2M = file.size / 1024 / 1024 < 5;
      if (!isLt2M) {
        this.$message.error("上传图片大小不能超过 5MB!");
        return isLt2M;
      }
      // 随机命名
      let random_name =
        this.random_string(6) +
        "_" +
        new Date().getTime() +
        "." +
        file.name.split(".").pop();
      this.$api.get(this, api.getoss).then((result) => {
        const client = new OSS.Wrapper({
          region: result.data.data.region,
          accessKeyId: result.data.data.accessKeyId,
          accessKeySecret: result.data.data.accessKeySecret,
          stsToken: result.data.data.securityToken,
          bucket: result.data.data.bucketName,
          endpoint: result.data.data.endpoint,
          secure: true,
        });
        client
          .multipartUpload(
            "goods/" + monthDay(new Date()) + "/" + random_name,
            file,
            {}
          )
          .then((results) => {
            return new Promise((resolve) => {
              this.formData.borderImage =
                result.data.data.hostname + results.name;
              resolve(true);
            });
          })
          .catch((err) => {
            console.log(err);
          });
      });
    },
    handleRemove() {
      this.formData.borderImage = "";
      this.fileList = [];
    },
    beforeUpload1(file) {
      if (
        file.type != "image/jpeg" &&
        file.type != "image/bmp" &&
        file.type != "image/jpg" &&
        file.type != "image/jpeg" &&
        file.type != "image/png" &&
        file.type != "image/gif"
      ) {
        this.$message.error("上传图片只能是bmp、jpg、jpeg、png、gif格式!");
        return false;
      }
      const isLt2M = file.size / 1024 / 1024 < 5;
      if (!isLt2M) {
        this.$message.error("上传图片大小不能超过 5MB!");
        return isLt2M;
      }
      // 随机命名
      let random_name =
        this.random_string(6) +
        "_" +
        new Date().getTime() +
        "." +
        file.name.split(".").pop();
      this.$api.get(this, api.getoss).then((result) => {
        const client = new OSS.Wrapper({
          region: result.data.region,
          accessKeyId: result.data.accessKeyId,
          accessKeySecret: result.data.accessKeySecret,
          stsToken: result.data.securityToken,
          bucket: result.data.bucketName,
          endpoint: result.data.endpoint,
          secure: true,
        });
        client
          .multipartUpload(
            "goods/" + monthDay(new Date()) + "/" + random_name,
            file,
            {}
          )
          .then((results) => {
            return new Promise((resolve) => {
              this.formData.backgroundImage =
                result.data.data.hostname + results.name;
              resolve(true);
            });
          })
          .catch((err) => {
            console.log(err);
          });
      });
    },
    handleRemove1() {
      this.formData.backgroundImage = "";
      this.fileList1 = [];
    },
    handleExceed(files, fileList) {
      if (files.length + fileList.length > 1) {
        this.$message.error("最多上传1个文件");
        return false;
      }
    },
    handleExceed1(files, fileList) {
      if (files.length + fileList.length > 1) {
        this.$message.error("最多上传1个文件");
        return false;
      }
    },
    // 随机生成文件名
    random_string(len) {
      len = len || 32;
      var chars = "ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678";
      var maxPos = chars.length;
      var pwd = "";
      for (let i = 0; i < len; i++) {
        pwd += chars.charAt(Math.floor(Math.random() * maxPos));
      }
      return pwd;
    },
  },
};
</script>

<style scoped>
/deep/.el-upload-list--picture-card .el-upload-list__item {
  width: 50px;
  height: 50px;
}
.button_box {
  margin-top: 100px;
}
.volume_box {
  font-size: 14px;
  color: #606266;
  display: flex;
}
.el-input {
  margin: 0 5px;
}
</style>
