import Vue from "vue";
import VueRouter from "vue-router";
Vue.use(VueRouter);
import login from "../views/login/Login.vue"; //登录页
import deviceType from "../views/filmcuttingmachine/devicetype/index.vue"; //设备类型
import add_devicetype from "../views/filmcuttingmachine/devicetype/add_devicetype.vue"; //设备类型详情
import deviceList from "../views/filmcuttingmachine/devicelist/index.vue"; //设备列表
import add_device from "../views/filmcuttingmachine/devicelist/add_device.vue"; //设备详情
import import_devicelist from "../views/filmcuttingmachine/devicelist/import_devicelist.vue"; //设备批量导入
import filmType from "../views/filmcuttingmachine/filmtype/index.vue"; //膜品类型
import add_filmtype from "../views/filmcuttingmachine/filmtype/add_filmtype.vue"; //膜品类型详情
import filmList from "../views/filmcuttingmachine/membranecode/index.vue"; //膜码列表
import import_membranecode from "../views/filmcuttingmachine/membranecode/import_membranecode.vue"; //导入膜码
import cutPackage from "../views/filmcuttingmachine/slitfilmPackage/index.vue"; //切膜套餐
import add_package from "../views/filmcuttingmachine/slitfilmPackage/add_package.vue"; //切膜套餐详情
import deviceOrder from "../views/filmcuttingmachine/order/index.vue"; //切膜订单
import times from "../views/filmcuttingmachine/universalfrequency/index.vue"; //次数管理
import channelConfiguration from "../views/filmcuttingmachine/channel/index.vue"; //渠道配置
import channelDetails from "../views/filmcuttingmachine/channel/channel_details.vue"; //渠道配置详情
import mappingType from "../views/filmcuttingmachine/mapping/index.vue"; //第三方映射分类列表
import mapping_details from "../views/filmcuttingmachine/mapping/mapping_details.vue"; //第三方映射分类详情
import diyOrderIndex from "../views/diy/order/index.vue"; //定制订单列表
import diyOrderdetails from "../views/diy/order/order_details.vue"; //定制订单详情
import couponList from "../views/marketing/coupon/index.vue"; //优惠券列表
import addCoupon from "../views/marketing/coupon/addCoupon.vue"; //编辑新增优惠券
import materialList from "../views/diy/materialList/index.vue"; //材料列表
import blockDiagramList from "../views/diy/blockDiagramList/index.vue"; //框图列表
import keyword from "../views/diy/keyword/index.vue"; //关键词列表
import modelList from "../views/diy/modelList/index.vue"; //型号列表
import imageList from "../views/diy/imageList/index.vue"; //图片列表
import shopList from "../views/diy/shopList/index.vue"; //门店列表
import storeDetail from "../views/diy/shopList/storeDetail.vue"; //门店详情
import import_shop from "../views/diy/shopList/import_shop.vue"; //导入店铺
import synchronizeDeviceRecords from "../views/filmcuttingmachine/synchronizeDeviceRecords/index.vue"; //同步记录
import Layout from "../views/index/index"; //首页

const routes = [
  {
    path: "/",
    name: "login",
    component: login,
  },
  {
    path: "/index",
    name: "index",
    component: Layout,
    children: [
      {
        path: "/deviceType",
        name: "deviceType",
        component: deviceType,
      },
      {
        path: "/add_devicetype",
        name: "add_devicetype",
        component: add_devicetype,
      },
      {
        path: "/deviceList",
        name: "deviceList",
        component: deviceList,
      },
      {
        path: "/add_device",
        name: "add_device",
        component: add_device,
      },
      {
        path: "/import_devicelist",
        name: "import_devicelist",
        component: import_devicelist,
      },
      {
        path: "/filmType",
        name: "filmType",
        component: filmType,
      },
      {
        path: "/add_filmtype",
        name: "add_filmtype",
        component: add_filmtype,
      },
      {
        path: "/filmList",
        name: "filmList",
        component: filmList,
      },
      {
        path: "/import_membranecode",
        name: "import_membranecode",
        component: import_membranecode,
      },
      {
        path: "/cutPackage",
        name: "cutPackage",
        component: cutPackage,
      },
      {
        path: "/add_package",
        name: "add_package",
        component: add_package,
      },
      {
        path: "/deviceOrder",
        name: "deviceOrder",
        component: deviceOrder,
      },
      {
        path: "/times",
        name: "times",
        component: times,
      },
      {
        path: "/channelConfiguration",
        name: "channelConfiguration",
        component: channelConfiguration,
      },
      {
        path: "/channelDetails",
        name: "channelDetails",
        component: channelDetails,
      },
      {
        path: "/mappingType",
        name: "mappingType",
        component: mappingType,
      },
      {
        path: "/mapping_details",
        name: "mapping_details",
        component: mapping_details,
      },
      {
        path: "/diyOrderIndex",
        name: "diyOrderIndex",
        component: diyOrderIndex,
      },
      {
        path: "/diyOrderdetails",
        name: "diyOrderdetails",
        component: diyOrderdetails,
      },
      {
        path: "/couponList",
        name: "couponList",
        component: couponList,
      },
      {
        path: "/addCoupon",
        name: "addCoupon",
        component: addCoupon,
      },
      {
        path: "/materialList",
        name: "materialList",
        component: materialList,
      },
      {
        path: "/blockDiagramList",
        name: "blockDiagramList",
        component: blockDiagramList,
      },
      {
        path: "/keyword",
        name: "keyword",
        component: keyword,
      },
      {
        path: "/modelList",
        name: "modelList",
        component: modelList,
      },
      {
        path: "/imageList",
        name: "imageList",
        component: imageList,
      },
      {
        path: "/shopList",
        name: "shopList",
        component: shopList,
      },
      {
        path: "/storeDetail",
        name: "storeDetail",
        component: storeDetail,
      },
      {
        path: "/import_shop",
        name: "import_shop",
        component: import_shop,
      },
      {
        path: "/synchronizeDeviceRecords",
        name: "synchronizeDeviceRecords",
        component: synchronizeDeviceRecords,
      },
    ],
  },
];
const router = new VueRouter({
  //   mode: "history",
  // base: '/backstage/',
  routes,
});
// 全局前置守卫
router.beforeEach((to, from, next) => {
  const isAuthenticated = !!localStorage.getItem("token"); // 假设你使用 localStorage 存储认证 token
  if (
    to.matched.some((record) => record.meta.requiresAuth) &&
    !isAuthenticated
  ) {
    next({
      path: "/", // 保存重定向的URL
    });
  } else {
    next();
  }
});
export default router;
