<template>
  <div>
    <div class="page_header_title_box" v-if="isShow">
      <i class="el-icon-back" @click="handleCancel"></i>
      新增店铺
    </div>
    <div class="page_header_title_box" v-else>
      <i class="el-icon-back" @click="handleCancel"></i>
      编辑店铺
    </div>
    <div style="padding: 70px 20px 20px 20px">
      <div class="content_box">
        <el-form
          label-width="150px"
          :rules="rules"
          ref="formData"
          :model="formData"
        >
          <el-form-item label="绑定分销商" prop="distributorId">
            <el-autocomplete
              clearable
              size="mini"
              style="width: 300px"
              v-model="formData.distributorName"
              :fetch-suggestions="querySearchAsync"
              placeholder="请输入分销商名称"
              @select="handleSelect"
              @clear="clearSelect"
            ></el-autocomplete>
          </el-form-item>
          <el-form-item label="平台编码" prop="platform">
            <el-input
              style="width: 300px"
              v-model="formData.platform"
              placeholder="请输入平台编码"
            ></el-input>
          </el-form-item>
          <el-form-item label="门店名称" prop="shopName">
            <el-input
              size="mini"
              style="width: 300px"
              v-model="formData.shopName"
              placeholder="请输入门店名称"
              maxlength="40"
            >
            </el-input>
          </el-form-item>
          <el-form-item label="门店编码" prop="shopCode">
            <el-input
              size="mini"
              style="width: 300px"
              v-model="formData.shopCode"
              placeholder="请输入门店编码"
              maxlength="40"
            >
            </el-input>
          </el-form-item>
          <el-form-item label="姓名" prop="name">
            <el-input
              size="mini"
              style="width: 300px"
              v-model="formData.name"
              placeholder="请输入姓名"
              maxlength="40"
            >
            </el-input>
          </el-form-item>
          <el-form-item label="手机号" prop="phone">
            <el-input
              size="mini"
              style="width: 300px"
              v-model="formData.phone"
              placeholder="请输入手机号"
              maxlength="40"
            >
            </el-input>
          </el-form-item>
          <el-form-item label="地区" prop="provinceId">
            <el-select
              v-model="formData.provinceId"
              placeholder="请选择"
              size="mini"
              filterable
              @change="provinceIdChange($event)"
            >
              <el-option
                v-for="province in this.RegionForChose.provinceId"
                :key="province.id"
                :label="province.regionName"
                :value="province.id"
              >
              </el-option>
            </el-select>
            <el-select
              v-model="formData.cityId"
              v-if="cityShow"
              placeholder="请选择"
              size="mini"
              filterable
              @change="cityIdChange($event)"
            >
              <el-option
                v-for="city in this.RegionForChose.cityId"
                :key="city.id"
                :label="city.regionName"
                :value="city.id"
              >
              </el-option>
            </el-select>
            <el-select
              v-model="formData.districtId"
              @change="districtIdChange($event)"
              v-if="townShow"
              placeholder="请选择"
              size="mini"
              filterable
            >
              <el-option
                v-for="town in this.RegionForChose.districtId"
                :key="town.id"
                :label="town.regionName"
                :value="town.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="详细地址" prop="address">
            <el-input
              size="mini"
              style="width: 300px"
              v-model="formData.address"
              placeholder="请输入详细地址"
              maxlength="40"
            >
            </el-input>
          </el-form-item>
          <el-form-item
            label="三方二维码规则"
            prop="extendParam"
            v-show="formData.distributorId === 2529"
          >
            <el-input
              type="textarea"
              v-model="formData.extendParam"
              placeholder="请输入三方二维码规则"
            ></el-input>
          </el-form-item>
          <el-form-item label="状态">
            <el-radio-group v-model="formData.openFlag">
              <el-radio :label="1">启用</el-radio>
              <el-radio :label="0">禁用</el-radio>
            </el-radio-group>
          </el-form-item>
        </el-form>
        <div class="clearfix footbtn">
          <el-button
            type="primary"
            :loading="dloading"
            style="margin-left: 46.5%"
            @click="handleSubmit()"
            >保存</el-button
          >
          <el-button @click="handleCancel()">返回</el-button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import api from "@/api/allUrl";
export default {
  name: "storeDetail",
  data() {
    // 手机正则验证
    const validatePhone = (rule, value, callback) => {
      if (
        /^(13[0-9]|14[5-9]|15[012356789]|166|17[0-8]|18[0-9]|19[0-9])[0-9]{8}$/.test(
          value
        ) === false
      ) {
        callback(new Error("请输入正确的手机号"));
      } else {
        callback();
      }
    };
    return {
      sid: null,
      RegionForChose: {
        // 被选region
        countryId: [],
        provinceId: [],
        cityId: [],
        districtId: [],
      },
      distriData: [],
      cityShow: false,
      townShow: false,
      isShow: true,
      formData: {
        platform: "",
        openFlag: 1,
        address: "",
        phone: "",
        name: "",
        id: "",
        shopCode: "",
        shopName: "",
        cityId: "",
        cityName: "",
        districtId: "",
        districtName: "",
        provinceId: "",
        provinceName: "",
        countryId: "37",
        countryName: "中国",
        distributorCompanyName: "",
        distributorId: "",
        distributorName: "",
        extendParam: "",
      },
      rules: {
        distributorId: [
          {
            required: true,
            message: "请选择分销商",
            trigger: "blur",
          },
        ],
        platform: [
          {
            required: true,
            message: "请输入台编码",
            trigger: "blur",
          },
        ],
        shopName: [
          {
            required: true,
            message: "请填写门店名称",
            trigger: "blur",
          },
        ],
        shopCode: [
          {
            required: true,
            message: "请填写门店编码",
            trigger: "blur",
          },
        ],
        name: [
          {
            required: true,
            message: "请输入姓名",
            trigger: "blur",
          },
        ],
        phone: [
          { required: true, message: "请输入手机号码", trigger: "blur" },
          { validator: validatePhone },
        ],
        provinceId: [
          {
            required: true,
            message: "请选择地区",
            trigger: "blur",
          },
        ],
        address: [
          {
            required: true,
            message: "请输入详细地址",
            trigger: "blur",
          },
        ],
        appName: [
          {
            required: true,
            message: "请选择微信支付账户",
            trigger: "change",
          },
        ],
      },
    };
  },
  activated() {
    if (this.$route.query.checkMsg == 1) {
      this.sid = this.$route.query.id;
      this.isShow = false;
      // 编辑
      this.initData();
    } else {
      this.isShow = true;
    }
    this.getaddressList();
  },
  methods: {
    // 提交
    handleSubmit() {
      this.$refs["formData"].validate((valid) => {
        if (!valid) {
          return false;
        } else {
          var moreComprehensiveCheck = /[\u4e00-\u9fff]/.test(
            this.formData.shopCode
          );
          if (moreComprehensiveCheck) {
            this.$message({
              message: "店铺编码不能包含中文",
              duration: 3 * 1000,
              onClose: () => {},
            });
          } else {
            if (this.sid) {
              // 编辑
              this.$api.put(this, api.editShop, this.formData).then((res) => {
                if (res.success) {
                  this.$message({
                    type: "success",
                    message: "编辑成功",
                  });
                  this.handleCancel();
                }
              });
            } else {
              // 新增
              this.$api.post(this, api.addShop, this.formData).then((res) => {
                if (res.success) {
                  this.$message({
                    type: "success",
                    message: "新增成功",
                  });
                  this.handleCancel();
                }
              });
            }
          }
        }
      });
    },
    // 获取数据
    initData() {
      this.$api
        .get(this, api.shopDetail, {
          id: this.sid,
        })
        .then((res) => {
          if (res.success) {
            this.formData = res.data;
            this.formData.openFlag = Number(res.data.openFlag);
            if (
              (this.formData.cityId != undefined &&
                this.formData.cityId != "") ||
              (this.formData.districtId != undefined &&
                this.formData.districtId != "")
            ) {
              this.cityShow = true;
              this.townShow = true;
              this.$api
                .get(this, api.regionListById, {
                  page: 1,
                  size: 200,
                  parentId: this.formData.provinceId,
                })
                .then((res) => {
                  if (res.success) {
                    this.cityShow = true;
                    this.RegionForChose.cityId = res.data.list;
                    if (res.data.list[0].haveNext === 1) {
                      this.$api
                        .get(this, api.regionListById, {
                          page: 1,
                          size: 200,
                          parentId: this.formData.cityId,
                        })
                        .then((res) => {
                          this.RegionForChose.districtId = res.data.list;
                        });
                      this.townShow = true;
                    } else {
                      this.townShow = false;
                      this.formData.districtId = 0;
                    }
                  }
                });
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    clearSelect() {
      this.formData.distributorId = "";
    },
    handleSelect(item) {
      this.formData.distributorCompanyName = item.value;
      this.formData.distributorId = item.id;
    },
    querySearchAsync(queryString, cb) {
      let pageInfo = {
        page: 1,
        size: 10,
        freezeStatus: 1,
        contentType: 1,
        content: queryString,
      };
      this.$api.get(this, api.getDistributorNPoList, pageInfo).then((res) => {
        if (res.success) {
          var restaurants = [];
          res.data.list.forEach((item) => {
            restaurants.push({
              value: item.name,
              id: item.id,
            });
          });
        }
        cb(restaurants);
      });
    },
    handleCancel() {
      // 返回操作
      this.$router.go(-1);
    },
    provinceIdChange(event) {
      // 省
      this.formData.provinceName = this.RegionForChose.provinceId.filter(
        (item) => item.id === event
      )[0].regionName;
      this.$api
        .get(this, api.regionListById, {
          page: 1,
          size: 200,
          parentId: event,
        })
        .then((res) => {
          if (res.success) {
            this.cityShow = true;
            this.RegionForChose.cityId = res.data.list;
            this.formData.cityId = res.data.list[0].id;
            this.formData.cityName = res.data.list[0].regionName;
            this.formData.districtId = "";
            this.formData.districtName = "";
            if (res.data.list[0].haveNext === 1) {
              this.$api
                .get(this, api.regionListById, {
                  page: 1,
                  size: 200,
                  parentId: this.formData.cityId,
                })
                .then((res) => {
                  this.RegionForChose.districtId = res.data.list;
                  this.formData.districtId =
                    this.RegionForChose.districtId[1].id;
                  this.formData.districtName =
                    this.RegionForChose.districtId[1].regionName;
                });
              this.townShow = true;
            } else {
              this.townShow = false;
              this.formData.districtId = 0;
            }
          }
        });
    },
    cityIdChange(event) {
      this.RegionForChose.cityId.forEach((element) => {
        if (element.id == event) {
          this.formData.cityId = event;
          this.formData.cityName = element.regionName;
        }
      });
      // 市
      this.$api
        .get(this, api.regionListById, {
          page: 1,
          size: 200,
          parentId: event,
        })
        .then((res) => {
          if (res.data.list.length === 0) {
            this.townShow = false;
            this.formData.districtId = 0;
          } else {
            this.RegionForChose.districtId = res.data.list;
            this.formData.districtId = this.RegionForChose.districtId[1].id;
            this.formData.districtName =
              this.RegionForChose.districtId[1].regionName;
          }
        });
    },
    districtIdChange(event) {
      this.formData.districtId = event;
      this.RegionForChose.districtId.forEach((element) => {
        if (element.id == event) {
          this.formData.districtName = element.regionName;
        }
      });
    },
    getaddressList() {
      this.$api
        .get(this, api.regionListById, {
          page: 1,
          size: 200,
          parentId: 0,
        })
        .then((res) => {
          if (res.success) {
            this.RegionForChose.id = res.data.list;
            this.$api
              .get(this, api.regionListById, {
                page: 1,
                size: 1000,
                parentId: 37,
              })
              .then((res) => {
                this.RegionForChose.provinceId = res.data.list;
              });
          }
        });
    },
  },
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped></style>
