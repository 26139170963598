<template>
  <el-row class="tac">
    <div class="_slide_header_183ej_107">
      {{ moduleJson[selectIndex].header }}
    </div>
    <el-menu
      :default-active="defaultActive"
      :collapse="false"
      :collapse-transition="false"
      router
      class="el-menu-vertical-demo"
      @select="handleOpen"
    >
      <div class="_slide_sub_header">{{ moduleJson[selectIndex].header }}</div>
      <el-submenu
        v-for="(item, index) in moduleJson[selectIndex].category"
        :key="index"
        :index="index"
      >
        <template slot="title">
          <span>{{ item.title }}</span>
        </template>
        <el-menu-item-group>
          <el-menu-item
            v-for="(menu, ind) in item.menuList"
            :key="ind"
            :index="menu.link"
          >
            <img class="rhombus_icon_img" src="../../images/rhombus_icon.png" />
            {{ menu.name }}
          </el-menu-item>
        </el-menu-item-group>
      </el-submenu>
    </el-menu>
  </el-row>
</template>
<script>
import moduleJson from "./module.json";
export default {
  name: "AsidePanel",
  props: {
    selectIndex: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      moduleJson: moduleJson,
      defaultActive: localStorage.getItem("defaultActive"),
    };
  },
  activated() {},
  methods: {
    handleOpen(key, keyPath) {
      localStorage.setItem("defaultActive", keyPath[keyPath.length - 1]);
    },
  },
};
</script>
<style scoped>
.rhombus_icon_img {
  width: 10px;
  height: 10px;
  margin-right: 5px;
}
._slide_header_183ej_107 {
  height: 48px;
  line-height: 48px;
  padding-left: 12px;
  border-bottom: 1px solid #f5f6f7;
}

._slide_sub_header {
  padding: 0 12px;
  height: 48px;
  line-height: 48px;
  color: #91959b;
  font-size: 12px;
  white-space: nowrap;
}

/deep/.el-submenu__title {
  height: 31px;
  line-height: 31px;
  list-style: none;
  padding-left: 12px !important;
}

/deep/.el-submenu__title:hover {
  background: #f5f6f7;
}

.el-menu-item:focus,
.el-menu-item:hover {
  background: #f5f6f7;
}

.el-menu-item,
.el-submenu__title {
  height: 32px;
  line-height: 32px;
  margin-bottom: 8px;
}

.el-menu-item {
  display: flex;
  align-items: center;
  padding-left: 12px !important;
}

.el-submenu .el-menu-item {
  height: 30px;
  line-height: 30px;
  padding: 0 45px;
  min-width: 200px;
}

.el-menu-item.is-active {
  color: #ff7500;
}
</style>
