<template>
  <div>
    <div class="page_header_title_box">框图列表</div>
    <div style="padding: 70px 20px 20px 20px">
      <div class="content_box">
        <div class="heading_box">
          <div class="search_for_box">
            <el-button class="dd_btn" @click="clickDrawer()">+ 新增</el-button>
            <!-- <el-button class="dd_btn">批量导入</el-button> -->
            <el-button class="dd_btn" @click="batchDelete()"
              >批量删除</el-button
            >
            <el-button @click="batchenableDisable(0)">批量启用</el-button>
            <el-button @click="batchenableDisable(1)">批量禁用</el-button>
            <!-- <el-button class="dd_btn">批量修改编码</el-button>
            <el-button class="dd_btn">批量修改体积</el-button>
            <el-button class="dd_btn">批量修改重量</el-button>
            <el-button class="dd_btn">修改裁切尺寸</el-button>
            <el-button class="dd_btn">批量传图</el-button> -->
          </div>
          <div class="search_for_box search_for">
            <el-input placeholder="请输入内容" v-model="pageInfo.materialName">
              <el-button
                slot="append"
                icon="el-icon-search"
                @click.prevent="onSearch()"
              ></el-button>
            </el-input>
            <el-button
              class="dd_btn"
              style="margin-left: 10px"
              @click="advanced = !advanced"
            >
              <i class="el-icon-caret-bottom" v-if="advanced"></i>
              <i class="el-icon-caret-top" v-else></i>
              高级筛选
            </el-button>
            <el-button class="dd_btn" @click="resetFilter()">重置</el-button>
          </div>
        </div>
        <div class="heading_box" v-show="advanced">
          <div class="search_for_box"></div>
          <div class="search_for_box search_for">
            <!-- <el-select size="mini" placeholder="材料分类" style="width: 100px;margin-right: 5px;" clearable>
              <el-option label="启用" :value="1"></el-option>
              <el-option label="禁用" :value="2"></el-option>
            </el-select> -->
            <el-cascader
              style="width: 100px; margin-right: 5px"
              placeholder="材料分类"
              :options="categorylist"
              :show-all-levels="false"
              :props="{ label: 'name', value: 'id' }"
              @change="selectcategorylist"
            ></el-cascader>
            <el-select
              style="width: 100px; margin-right: 5px"
              size="mini"
              placeholder="材料名称"
              clearable
              v-model="pageInfo.materialId"
            >
              <el-option
                :value="item.id"
                :label="item.name"
                v-for="(item, index) in materialList"
                :key="index"
              ></el-option>
            </el-select>
            <el-cascader
              style="width: 100px; margin-right: 5px"
              placeholder="型号分类"
              :options="modelTypelist"
              :show-all-levels="false"
              v-model="pageInfo.modelId"
              :props="{ label: 'name', value: 'id' }"
            ></el-cascader>
          </div>
        </div>
        <el-table
          ref="multipleSelect"
          :data="tableData"
          header-row-class-name="header-row"
          @select="select"
          @select-all="selectAll"
          @selection-change="handleSelectionChange"
        >
          <el-table-column
            type="selection"
            width="50"
            align="center"
          ></el-table-column>
          <el-table-column
            label="ID"
            align="center"
            prop="id"
          ></el-table-column>
          <el-table-column
            label="材料分类"
            align="center"
            prop="materialCategory"
          >
            <template slot-scope="scope">
              {{ scope.row.materialCategory.name }}>{{
                scope.row.materialCategory.children[0].name
              }}
            </template>
          </el-table-column>
          <el-table-column label="材料名称" align="center">
            <template slot-scope="scope">
              {{ scope.row.material.name }}
            </template>
          </el-table-column>
          <el-table-column label="型号分类" align="center" prop="">
            <template slot-scope="scope">
              {{ scope.row.modelCategory.name }}>{{
                scope.row.modelCategory.children[0].name
              }}
            </template>
          </el-table-column>
          <el-table-column label="型号" align="center" prop="">
            <template slot-scope="scope">
              {{ scope.row.model.nameCn }}
            </template>
          </el-table-column>
          <el-table-column
            label="第三方编码"
            align="center"
            prop="thirdPartyFactoryCode"
          ></el-table-column>
          <el-table-column label="外框图" align="center" prop="">
            <template slot-scope="scope">
              <el-image
                style="width: 50px; height: 50px"
                :src="scope.row.borderImage"
                fit="contain"
                :preview-src-list="[scope.row.borderImage]"
              >
              </el-image>
            </template>
          </el-table-column>
          <el-table-column label="底图" align="center" prop="">
            <template slot-scope="scope">
              <el-image
                style="width: 50px; height: 50px"
                :src="scope.row.backgroundImage"
                fit="contain"
                :preview-src-list="[scope.row.backgroundImage]"
              >
              </el-image>
            </template>
          </el-table-column>
          <el-table-column
            label="颜色"
            align="center"
            prop="color"
          ></el-table-column>
          <el-table-column label="长*宽*高（mm）" align="center" prop="">
            <template slot-scope="scope">
              {{ scope.row.length }}* {{ scope.row.width }}*
              {{ scope.row.height }}
            </template>
          </el-table-column>
          <el-table-column
            label="重量（g）"
            align="center"
            prop="weight"
          ></el-table-column>
          <el-table-column label="裁切尺寸（mm）" align="center" prop="">
            <template slot-scope="scope">
              {{ scope.row.top }}* {{ scope.row.bottom }}*
              {{ scope.row.left }}*{{ scope.row.right }}
            </template>
          </el-table-column>
          <el-table-column label="状态" align="center" prop="">
            <template slot-scope="scope">
              <div
                :class="
                  scope.row.status == 1
                    ? 'closed_status_box'
                    : 'startup_status_box'
                "
                @click="enableDisable(scope.row)"
              >
                {{ scope.row.status == 1 ? "禁用" : "启用" }}
                <i class="el-icon-video-play" v-if="scope.row.status == 1"></i>
                <i class="el-icon-video-pause" v-else></i>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="操作" align="center" fixed="right">
            <template slot-scope="scope">
              <div class="operating_button_box">
                <div class="startup_status_box" @click="edit(scope.row)">
                  编辑
                </div>
                <div
                  class="closed_status_box"
                  @click="deleteMaterialList(scope.row.id)"
                >
                  删除
                </div>
              </div>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          :total="total"
          :page="pageInfo.page"
          @size-change="onSizeCHange"
          @current-change="onCurrentChange"
        >
        </el-pagination>
      </div>
    </div>
    <el-drawer
      :title="drawerTiele"
      :visible.sync="drawer"
      :direction="direction"
      :before-close="handleClose"
    >
      <editImg
        :blockDiagramDetails="blockDiagramDetails"
        @handleClose="handleClose"
      ></editImg>
    </el-drawer>
  </div>
</template>
<script>
import editImg from "./components/editImg.vue";
import api from "@/api/allUrl";
export default {
  components: { editImg },
  name: "blockDiagramList",
  data() {
    return {
      modelTypelist: [],
      categorylist: [],
      blockDiagramDetails: {},
      drawerTiele: "新建框图",
      drawer: false,
      direction: "rtl",
      materialList: [],
      tableData: [],
      loading: false,
      advanced: false,
      pageInfo: {
        page: 1,
        size: 10,
        materialName: "",
        materialId: "",
        modelId: "",
      },
      total: 0,
      isSelect: false,
      multipleSelect: [],
    };
  },
  activated() {
    this.loading = true;
    this.getBorderimageslist();
    this.getMaterialCategorylist();
    this.getModel_Typelist();
    // this.drawer = true;
  },
  methods: {
    resetFilter() {
      this.pageInfo = {
        page: 1,
        size: 10,
        materialName: "",
        materialId: "",
        modelId: "",
      };
      this.getBorderimageslist();
    },
    getModel_Typelist() {
      this.$api.get(this, api.getModelTypelist).then((res) => {
        if (res.success) {
          this.modelTypelist = res.data;
        }
      });
    },
    selectcategorylist(val) {
      this.getMaterialList(val[val.length - 1]);
    },
    getMaterialList(categoryId) {
      this.$api
        .get(this, api.getMaterialList, {
          page: 1,
          size: 100,
          status: 0,
          categoryId: categoryId,
        })
        .then((res) => {
          if (res.success) {
            this.materialList = res.data.list;
          }
        });
    },
    getMaterialCategorylist() {
      this.$api.get(this, api.getMaterialCategorylist).then((res) => {
        if (res.success) {
          this.categorylist = res.data;
        }
      });
    },
    handleClose() {
      this.drawer = false;
      this.getBorderimageslist();
    },
    clickDrawer() {
      this.blockDiagramDetails = {};
      this.drawerTiele = "新建框图";
      this.drawer = true;
    },
    batchenableDisable(status) {
      if (this.multipleSelect.length == 0) {
        this.$message({
          message: "请选择需要启禁用的图片",
        });
      } else {
        var title = "此操作将启用所选图片，是否继续？";
        var message = "启用成功";
        if (status == 1) {
          title = "此操作将禁用所选图片，是否继续？";
          message = "禁用成功";
        }
        this.$confirm(title, "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
          center: true,
        })
          .then(() => {
            var ids = [];
            this.multipleSelect.forEach((item) => {
              ids.push(item.id);
            });
            this.$api
              .post(this, api.batchupdateBorderimageslist, {
                ids: ids,
                status: status,
              })
              .then(() => {
                this.$message({
                  message: message,
                });
                this.getBorderimageslist();
              });
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消操作",
            });
          });
      }
    },
    batchDelete() {
      if (this.multipleSelect.length == 0) {
        this.$message({
          message: "请选择需要启禁用的图片",
        });
      } else {
        this.$confirm("此操作将删除所选图片，是否继续？", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
          center: true,
        })
          .then(() => {
            var ids = [];
            this.multipleSelect.forEach((item) => {
              ids.push(item.id);
            });
            this.$api
              .delete(this, api.batchudeleteBorderimageslist, { ids: ids })
              .then(() => {
                this.$message({
                  message: "删除成功",
                });
                this.getBorderimageslist();
              });
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消操作",
            });
          });
      }
    },
    edit(item) {
      this.blockDiagramDetails = item;
      this.drawerTiele = "编辑框图";
      this.drawer = true;
    },
    deleteMaterialList(id) {
      this.$confirm("此操作将删除该图片，是否继续？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
        center: true,
      })
        .then(() => {
          this.$api
            .delete(this, api.deleteBorderimageslist, { ids: [id] })
            .then(() => {
              this.$message({
                message: "删除成功",
              });
              this.getBorderimageslist();
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消操作",
          });
        });
    },
    enableDisable(item) {
      var message = "是否确认启用该图片？";
      if (item.status == 0) {
        message = "是否确认禁用该图片？";
      }
      this.$confirm(message, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
        center: true,
      })
        .then(() => {
          if (item.status == 0) {
            item.status = 1;
          } else {
            item.status = 0;
          }
          this.$api.put(this, api.updateBorderimageslist, item).then((res) => {
            if (res.success) {
              this.$message({
                message: "修改成功",
              });
              this.getBorderimageslist();
            }
          });
        })
        .catch(() => {
          // 处理取消操作
        });
    },
    onSearch() {
      this.pageInfo.page = 1;
      this.getBorderimageslist();
    },
    getBorderimageslist() {
      this.$api
        .get(this, api.getBorderimageslist, this.pageInfo)
        .then((res) => {
          if (res.success) {
            this.tableData = res.data.list;
            this.total = res.data.total;
            setTimeout(() => {
              this.multipleSelect.forEach((row1) => {
                //重新获取数据时，判断哪些选中了
                if (row1 != undefined) {
                  this.tableData.forEach((row2) => {
                    if (row1.id == row2.id) {
                      this.$refs.multipleSelect.toggleRowSelection(row2);
                    }
                  });
                }
              });
            }, 100);
          }
        });
    },
    onSizeCHange(size) {
      this.pageInfo.size = size;
      this.pageInfo.page = 1;
      this.getBorderimageslist();
    },
    onCurrentChange(page) {
      this.pageInfo.page = page;
      this.getBorderimageslist();
    },
    //去重
    setArr(arr) {
      let obj = {};
      let temp = [];
      for (let i = 0; i < arr.length; i++) {
        let type = Object.prototype.toString.call(arr[i].id); //不加类型 分不清 1 '1'
        if (!obj[arr[i].id + type]) {
          temp.push(arr[i]);
          obj[arr[i].id + type] = true; //这里给true 利于代码阅读和判断。  如果给 0,'' ,false ,undefined 都会在if那里判断为 false 不利于代码阅读
        }
      }
      return temp;
    },
    select(selection, row) {
      //单选时调用
      this.isSelect = true;
      let d = false;
      for (let i = 0; i < this.multipleSelect.length; i++) {
        if (this.multipleSelect[i].id === row.id) {
          this.multipleSelect.splice(i, 1);
          d = true;
          break;
        }
      }
      if (!d) {
        this.multipleSelect.push(row);
        this.multipleSelect = this.setArr(this.multipleSelect);
      }
    },
    selectAll(selection) {
      //全选时调用
      this.isSelect = true;
      if (selection.length === 0) {
        this.tableData.forEach((row) => {
          for (let i = 0; i < this.multipleSelect.length; i++) {
            if (this.multipleSelect[i].id === row.id) {
              this.multipleSelect.splice(i, 1);
              break;
            }
          }
        });
      } else {
        this.multipleSelect = this.setArr(
          this.multipleSelect.concat(selection)
        );
      }
    },
    handleSelectionChange(val) {
      //当切换页面时的作用
      if (
        val.length === 0 &&
        this.multipleSelect.length != 0 &&
        !this.isSelect
      ) {
        this.multipleSelect.forEach((row1) => {
          this.tableData.forEach((row2) => {
            if (row1.id === row2.id) {
              this.$refs.multipleSelect.toggleRowSelection(row2);
            }
          });
        });
        this.isSelect = true;
      }
    },
    checkSelectable(row) {
      return row.isDisabled !== 1;
    },
  },
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.operating_button_box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}
.closed_status_box {
  color: red;
  cursor: pointer;
}
.startup_status_box {
  color: #ff7500;
  cursor: pointer;
}
</style>
