<template>
  <div>
    <div class="material_title_box">
      {{ checkMsg == 0 ? "新建图片" : "编辑图片" }}
    </div>
    <div class="material_linq"></div>
    <div class="material_form_box">
      <el-form
        :model="formData"
        :rules="rules"
        label-width="20%"
        label-position="right"
        ref="formData"
      >
        <!-- <el-form-item label="图片分类" prop="categoryIds">
          <el-input v-model="formData.categoryIds" placeholder="请输入材料名称" />
        </el-form-item> -->
        <el-form-item label="图片名称" prop="nameCn">
          <el-input v-model="formData.nameCn" placeholder="请输入材料编码" />
        </el-form-item>
        <el-form-item label="图片英文名称" prop="nameEn">
          <el-input v-model="formData.nameEn" placeholder="请输入材料描述" />
        </el-form-item>
        <el-form-item label="素材类型" prop="type">
          <el-input v-model="formData.type" placeholder="请输入材料描述" />
        </el-form-item>
        <el-form-item label="图片编码" prop="code">
          <el-input v-model="formData.code" placeholder="请输入图片编码" />
        </el-form-item>
        <el-form-item label="主题链接" prop="subjectUrl">
          <el-input
            v-model="formData.subjectUrl"
            placeholder="请输入主题链接"
          />
        </el-form-item>
        <el-form-item label="关键词/描述" prop="description">
          <el-input
            v-model="formData.description"
            placeholder="请输入关键词/描述"
          />
        </el-form-item>
        <el-form-item label="排序" prop="sortNum">
          <el-input
            type="number"
            v-model="formData.sortNum"
            placeholder="请输入排序"
          />
        </el-form-item>
        <el-form-item label="生产图" prop="productImageUrl">
          <el-upload
            :action="action"
            :headers="headers"
            list-type="picture-card"
            :limit="1"
            :on-exceed="handleExceed"
            :on-remove="handleRemove"
            :before-upload="beforeUpload"
            :file-list="fileList"
          >
            <i slot="default" class="el-icon-plus"></i>
          </el-upload>
        </el-form-item>
        <el-form-item label="缩略图" prop="thumbImageUrl">
          <el-upload
            :action="action"
            :headers="headers"
            list-type="picture-card"
            :limit="1"
            :on-exceed="handleExceed1"
            :on-remove="handleRemove1"
            :before-upload="beforeUpload1"
            :file-list="fileList1"
          >
            <i slot="default" class="el-icon-plus"></i>
          </el-upload>
        </el-form-item>
        <el-form-item
          label="可用材料分类 "
          style="margin-bottom: 0px"
          prop="materialCategoryIds"
        >
          <el-select
            v-model="formData.materialCategoryIds"
            multiple
            placeholder="请选择"
          >
            <el-option
              v-for="item in categorylist"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="状态" style="margin-bottom: 0px">
          <el-radio-group v-model="formData.status" size="mini">
            <el-radio :label="0">启用</el-radio>
            <el-radio :label="1">禁用</el-radio>
          </el-radio-group>
        </el-form-item>
      </el-form>
    </div>
    <div class="button_box">
      <div class="exchange_bit" @click="submitData()">保存</div>
      <div class="return_bit" @click="returnList()">返回</div>
    </div>
  </div>
</template>
<script src="https://gosspublic.alicdn.com/aliyun-oss-sdk-4.4.4.min.js"></script>
<script>
import { monthDay } from "@/utils/common";
import axios from "axios";
import api from "@/api/allUrl";
export default {
  props: ["categoryId", "checkMsg", "materialListId"],
  name: "materialAdd",
  data() {
    return {
      action:
        process.env.VUE_APP_API_URL + "/device/system/v1/web/admin/oss/sts",
      headers: {
        Accept: "application/json",
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        Platform: "GF60004",
        Version: "1.0.0",
        token: localStorage.getItem("token"),
        "Cache-Control": "no-cache",
        Pragma: "no-cache",
      },
      categorylist: [],
      formData: {
        categoryIds: [],
        code: "",
        description: "",
        materialCategoryIds: [],
        nameCn: "",
        nameEn: "",
        productImageUrl: "",
        status: 0,
        subjectUrl: "",
        thumbImageUrl: "",
        type: "",
      },
      rules: {
        categoryIds: [
          { required: true, message: "请输入图片类型", trigger: "blur" },
        ],
        code: [{ required: true, message: "请输入图片编码", trigger: "blur" }],
        materialCategoryIds: [
          { required: true, message: "请选择材质分类", trigger: "blur" },
        ],
        nameCn: [
          { required: true, message: "请输入图片名称", trigger: "blur" },
        ],
        productImageUrl: [
          { required: true, message: "请上传生产图片", trigger: "blur" },
        ],
        thumbImageUrl: [
          { required: true, message: "请上传缩略图", trigger: "blur" },
        ],
        sortNum: [{ required: true, message: "请输入排序", trigger: "blur" }],
      },
    };
  },
  activated() {
    if (this.checkMsg == 1) {
      this.$api
        .get(this, api.getImagesListdetails, {
          id: this.materialListId,
        })
        .then((res) => {
          this.formData = res.data;
          this.formData.materialCategoryIds = [];
          this.formData.materialCategories.forEach((item) => {
            this.formData.materialCategoryIds.push(item.id);
          });

          this.fileList = [
            { name: "food.jpeg", url: this.formData.productImageUrl },
          ];
          this.fileList1 = [
            { name: "food.jpeg", url: this.formData.thumbImageUrl },
          ];
        });
    }
    this.getMaterialCategorylist();
  },
  methods: {
    getMaterialCategorylist() {
      this.$api.get(this, api.getMaterialCategorylist).then((res) => {
        if (res.success) {
          this.categorylist = res.data;
        }
      });
    },
    returnList() {
      this.$emit("returnList");
    },
    submitData() {
      this.$refs["formData"].validate((valid) => {
        this.formData.categoryIds = [this.categoryId];
        if (valid) {
          if (this.checkMsg == 0) {
            this.$api
              .post(this, api.addImagesList, this.formData)
              .then((res) => {
                if (res.success) {
                  this.$message({
                    message: "新增成功",
                  });
                  this.returnList();
                }
              });
          } else {
            delete this.formData.imagesCategories;
            delete this.formData.materialCategories;
            this.$api
              .post(this, api.updateImagesList, this.formData)
              .then((res) => {
                if (res.success) {
                  this.$message({
                    message: "修改成功",
                  });
                  this.returnList();
                }
              });
          }
        }
      });
    },
    handleRemove(file) {
      this.formData.productImageUrl = "";
      this.fileList = [];
    },
    beforeUpload(file) {
      if (
        file.type != "image/jpeg" &&
        file.type != "image/bmp" &&
        file.type != "image/jpg" &&
        file.type != "image/jpeg" &&
        file.type != "image/png" &&
        file.type != "image/gif"
      ) {
        this.$message.error("上传图片只能是bmp、jpg、jpeg、png、gif格式!");
        return false;
      }
      const isLt2M = file.size / 1024 / 1024 < 5;
      if (!isLt2M) {
        this.$message.error("上传图片大小不能超过 5MB!");
        return isLt2M;
      }
      const _self = this;
      // 随机命名
      let random_name =
        _self.random_string(6) +
        "_" +
        new Date().getTime() +
        "." +
        file.name.split(".").pop();

      axios({
        method: "get",
        url:
          process.env.VUE_APP_API_URL + "/device/system/v1/web/admin/oss/sts",
        headers: {
          Accept: "application/json",
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
          Platform: "GF60004",
          Version: "1.0.0",
          token: localStorage.getItem("token"),
          "Cache-Control": "no-cache",
          Pragma: "no-cache",
        },
        withCredentials: false,
      }).then((result) => {
        console.log("数据：", result);
        const client = new OSS.Wrapper({
          region: result.data.data.region,
          accessKeyId: result.data.data.accessKeyId,
          accessKeySecret: result.data.data.accessKeySecret,
          stsToken: result.data.data.securityToken,
          bucket: result.data.data.bucketName,
          endpoint: result.data.data.endpoint,
          secure: true,
        });
        // 上传
        client
          .multipartUpload(
            "goods/" + monthDay(new Date()) + "/" + random_name,
            file,
            {}
          )
          .then((results) => {
            return new Promise((resolve) => {
              this.formData.productImageUrl =
                result.data.data.hostname + results.name;
              console.log("图片列表：", this.formData.productImageUrl);
              resolve(true);
            });
          })
          .catch((err) => {
            console.log(err);
          });
      });
    },

    handleExceed(files, fileList) {
      if (files.length + fileList.length > 1) {
        this.$message.error("最多上传1个文件");
        return false;
      }
    },
    handleRemove1(file) {
      this.formData.thumbImageUrl = "";
      this.fileList1 = [];
    },
    beforeUpload1(file) {
      if (
        file.type != "image/jpeg" &&
        file.type != "image/bmp" &&
        file.type != "image/jpg" &&
        file.type != "image/jpeg" &&
        file.type != "image/png" &&
        file.type != "image/gif"
      ) {
        this.$message.error("上传图片只能是bmp、jpg、jpeg、png、gif格式!");
        return false;
      }
      const isLt2M = file.size / 1024 / 1024 < 5;
      if (!isLt2M) {
        this.$message.error("上传图片大小不能超过 5MB!");
        return isLt2M;
      }
      const _self = this;
      // 随机命名
      let random_name =
        _self.random_string(6) +
        "_" +
        new Date().getTime() +
        "." +
        file.name.split(".").pop();
      axios({
        method: "get",
        url:
          process.env.VUE_APP_API_URL + "/device/system/v1/web/admin/oss/sts",
        headers: {
          Accept: "application/json",
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
          Platform: "GF60004",
          Version: "1.0.0",
          token: localStorage.getItem("token"),
          "Cache-Control": "no-cache",
          Pragma: "no-cache",
        },
        withCredentials: false,
      }).then((result) => {
        const client = new OSS.Wrapper({
          region: result.data.data.region,
          accessKeyId: result.data.data.accessKeyId,
          accessKeySecret: result.data.data.accessKeySecret,
          stsToken: result.data.data.securityToken,
          bucket: result.data.data.bucketName,
          endpoint: result.data.data.endpoint,
          secure: true,
        });
        // 上传
        client
          .multipartUpload(
            "goods/" + monthDay(new Date()) + "/" + random_name,
            file,
            {}
          )
          .then((results) => {
            return new Promise((resolve) => {
              this.formData.thumbImageUrl =
                result.data.data.hostname + results.name;
              resolve(true);
            });
          })
          .catch((err) => {
            console.log(err);
          });
      });
    },

    handleExceed1(files, fileList) {
      if (files.length + fileList.length > 1) {
        this.$message.error("最多上传1个文件");
        return false;
      }
    },
    // 随机生成文件名
    random_string(len) {
      len = len || 32;
      var chars = "ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678";
      var maxPos = chars.length;
      var pwd = "";
      for (let i = 0; i < len; i++) {
        pwd += chars.charAt(Math.floor(Math.random() * maxPos));
      }
      return pwd;
    },
  },
};
</script>

<style rel="stylesheet/scss" scoped>
/deep/.el-upload-list--picture-card .el-upload-list__item {
  width: 50px;
  height: 50px;
}
</style>
