<template>
  <div>
    <div class="page_header_title_box">图片列表</div>
    <div class="template_style_box">
      <div class="left_template_box">
        <div class="template_title_box">
          <div>图片分类</div>
          <el-button class="dd_btn" @click="cikAdd(0, {})">+ 新增</el-button>
        </div>
        <div v-if="imagesType.length > 0">
          <div v-for="(obj, index) in imagesType" :key="index">
            <div
              @click="seleType(index, obj)"
              :class="
                categoryId == obj.id
                  ? 'material_item_box selece_material_item_box'
                  : 'material_item_box'
              "
            >
              <div class="material_type_box">
                <img
                  class="file_icon_img"
                  src="../../../images/file_icon.png"
                />
                {{ obj.name
                }}{{ obj.childrenCount ? "（" + obj.childrenCount + "）" : "" }}
              </div>
              <div class="operation_module_box" v-if="categoryId == obj.id">
                <i class="el-icon-edit" @click.stop="cikAdd(-1, obj)"></i>
                <!-- <i class="el-icon-circle-plus-outline" @click.stop="cikAdd(1, {},obj.id)"></i> -->
                <i
                  class="el-icon-circle-close"
                  @click.stop="deleteMaterial(obj.id)"
                  v-if="obj.status == 1"
                ></i>
              </div>
            </div>
            <div
              v-if="
                level1index == index &&
                obj.children != undefined &&
                obj.children.length > 0
              "
            >
              <div v-for="(item, indx) in obj.children" :key="indx">
                <div
                  @click="selectlevel2(indx, item)"
                  :class="
                    categoryId == item.id
                      ? 'material_item_box selece_material_item_box'
                      : 'material_item_box'
                  "
                >
                  <div class="material_type_box">
                    <i
                      :class="
                        level2Index == indx
                          ? 'el-icon-caret-bottom'
                          : 'el-icon-caret-right'
                      "
                    ></i>
                    {{ item.name }}
                  </div>
                  <div
                    class="operation_module_box"
                    v-if="categoryId == item.id"
                  >
                    <i class="el-icon-edit" @click.stop="cikAdd(-1, item)"></i>
                    <i
                      class="el-icon-circle-plus-outline"
                      @click.stop="cikAdd(1, {}, item.id)"
                    ></i>
                    <i
                      class="el-icon-circle-close"
                      @click.stop="deleteMaterial(item.id)"
                      v-if="item.status == 1"
                    ></i>
                  </div>
                </div>
                <div
                  v-if="
                    level2Index == indx &&
                    item.children != undefined &&
                    item.children.length > 0
                  "
                >
                  <div
                    :class="
                      categoryId == data.id
                        ? 'material_item_box selece_material_item_box'
                        : 'material_item_box'
                    "
                    v-for="(data, val) in item.children"
                    :key="val"
                    @click="viewMaterial(data.id)"
                  >
                    <div class="material_type_box">
                      <div class="subcategory_box"></div>
                      {{ data.name }}
                    </div>
                    <div
                      class="operation_module_box"
                      v-if="categoryId == data.id"
                    >
                      <i
                        class="el-icon-edit"
                        @click.stop="cikAdd(-1, data, '', true)"
                      ></i>
                      <i
                        class="el-icon-circle-close"
                        @click.stop="deleteMaterial(data.id)"
                        v-if="data.status == 1"
                      ></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-else class="no_data_available">暂无图片~</div>
      </div>
      <div class="right_template_box">
        <category
          v-if="
            addtype != -2 && isViewMaterial == false && isMaterialAdd == false
          "
          :managerId="managerId"
          :addtype="addtype"
          :categoryData="categoryData"
          @updateList="getImagesTypeList"
        ></category>
        <image_List
          v-if="isViewMaterial"
          :categoryId="categoryId"
          @addMaterial="addMaterial"
          @editMaterial="editMaterial"
        ></image_List>
        <imageAdd
          v-if="isMaterialAdd"
          :categoryId="categoryId"
          :materialListId="materialListId"
          :checkMsg="checkMsg"
          @returnList="returnList"
        ></imageAdd>
      </div>
    </div>
  </div>
</template>

<script>
import api from "@/api/allUrl";
import category from "./compomemts/category.vue"; //一级分类
import image_List from "./compomemts/imageList.vue"; //图片列表
import imageAdd from "./compomemts/imageAdd.vue"; //新增图片
export default {
  name: "imageList",
  components: { category, image_List, imageAdd },
  data() {
    return {
      imagesType: [],
      level1index: -1,
      level2Index: -1,
      level2Show: false,
      categoryId: -1,
      addtype: -2,
      categoryData: {},
      managerId: "",
      isViewMaterial: false,
      isMaterialAdd: false,
      checkMsg: 0,
      materialListId: "",
    };
  },
  activated() {
    this.getImagesTypeList();
  },
  methods: {
    returnList() {
      this.isMaterialAdd = false;
      this.isViewMaterial = true;
    },
    editMaterial(id) {
      this.materialListId = id;
      this.isViewMaterial = false;
      this.isMaterialAdd = true;
      this.checkMsg = 1;
    },
    addMaterial(checkMsg) {
      this.isViewMaterial = false;
      this.isMaterialAdd = true;
      this.checkMsg = checkMsg;
    },
    viewMaterial(id) {
      this.isViewMaterial = true;
      this.categoryId = id;
    },
    cikAdd(index, item, parentId, isView) {
      this.isViewMaterial = false;
      this.isMaterialAdd = false;
      this.manager = "";
      if (parentId != undefined) {
        this.managerId = parentId;
      }
      if (item.id == undefined) {
        this.categoryData = {};
      } else {
        this.categoryData = item;
      }
      this.addtype = index;
      if (isView) {
        this.categoryId = item.id;
      }
    },

    getImagesTypeList() {
      this.$api.get(this, api.getImagesTypeList).then((res) => {
        if (res.success) {
          this.imagesType = res.data;
        }
      });
    },
    selectlevel2(index, item) {
      if (this.level2Show && this.categoryId != item.id) {
        this.categoryId = item.id;
        this.level2Index = index;
      } else {
        this.level2Show = !this.level2Show;
        if (this.level2Show) {
          this.categoryId = item.id;
          this.level2Index = index;
        } else {
          this.level2Index = -1;
          this.categoryId = "";
        }
      }
      this.isViewMaterial = false;
      this.isMaterialAdd = false;
      if (item.isLeaf) {
        this.viewMaterial(item.id);
      }
    },
    seleType(index, item) {
      this.categoryId = item.id;
      this.level1index = index;
      this.level2Show = false;
      this.isViewMaterial = false;
      this.isMaterialAdd = false;
      if (item.isLeaf) {
        this.viewMaterial(item.id);
      }
    },
    deleteMaterial(id) {
      this.$confirm("此操作将删除该图片类型，是否继续？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
        center: true,
      })
        .then(() => {
          this.$api.delete(this, api.deleteImagesType, { id: id }).then(() => {
            this.$message({
              message: "删除成功",
            });
            this.addtype = -2;
            this.managerId = "";
            this.categoryData = {};
            this.getImagesTypeList();
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消操作",
          });
        });
    },
  },
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.dd_btn {
  padding: 5.6px 5px;
  font-size: 10px;
  border-radius: 4px;
  color: #ff7500;
  border: 1px solid #dbdfe6;
}
</style>
