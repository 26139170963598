var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"page_header_title_box"},[_vm._v("图片列表")]),_c('div',{staticClass:"template_style_box"},[_c('div',{staticClass:"left_template_box"},[_c('div',{staticClass:"template_title_box"},[_c('div',[_vm._v("图片分类")]),_c('el-button',{staticClass:"dd_btn",on:{"click":function($event){return _vm.cikAdd(0, {})}}},[_vm._v("+ 新增")])],1),(_vm.imagesType.length > 0)?_c('div',_vm._l((_vm.imagesType),function(obj,index){return _c('div',{key:index},[_c('div',{class:_vm.categoryId == obj.id
                ? 'material_item_box selece_material_item_box'
                : 'material_item_box',on:{"click":function($event){return _vm.seleType(index, obj)}}},[_c('div',{staticClass:"material_type_box"},[_c('img',{staticClass:"file_icon_img",attrs:{"src":require("../../../images/file_icon.png")}}),_vm._v(" "+_vm._s(obj.name)+_vm._s(obj.childrenCount ? "（" + obj.childrenCount + "）" : "")+" ")]),(_vm.categoryId == obj.id)?_c('div',{staticClass:"operation_module_box"},[_c('i',{staticClass:"el-icon-edit",on:{"click":function($event){$event.stopPropagation();return _vm.cikAdd(-1, obj)}}}),(obj.status == 1)?_c('i',{staticClass:"el-icon-circle-close",on:{"click":function($event){$event.stopPropagation();return _vm.deleteMaterial(obj.id)}}}):_vm._e()]):_vm._e()]),(
              _vm.level1index == index &&
              obj.children != undefined &&
              obj.children.length > 0
            )?_c('div',_vm._l((obj.children),function(item,indx){return _c('div',{key:indx},[_c('div',{class:_vm.categoryId == item.id
                    ? 'material_item_box selece_material_item_box'
                    : 'material_item_box',on:{"click":function($event){return _vm.selectlevel2(indx, item)}}},[_c('div',{staticClass:"material_type_box"},[_c('i',{class:_vm.level2Index == indx
                        ? 'el-icon-caret-bottom'
                        : 'el-icon-caret-right'}),_vm._v(" "+_vm._s(item.name)+" ")]),(_vm.categoryId == item.id)?_c('div',{staticClass:"operation_module_box"},[_c('i',{staticClass:"el-icon-edit",on:{"click":function($event){$event.stopPropagation();return _vm.cikAdd(-1, item)}}}),_c('i',{staticClass:"el-icon-circle-plus-outline",on:{"click":function($event){$event.stopPropagation();return _vm.cikAdd(1, {}, item.id)}}}),(item.status == 1)?_c('i',{staticClass:"el-icon-circle-close",on:{"click":function($event){$event.stopPropagation();return _vm.deleteMaterial(item.id)}}}):_vm._e()]):_vm._e()]),(
                  _vm.level2Index == indx &&
                  item.children != undefined &&
                  item.children.length > 0
                )?_c('div',_vm._l((item.children),function(data,val){return _c('div',{key:val,class:_vm.categoryId == data.id
                      ? 'material_item_box selece_material_item_box'
                      : 'material_item_box',on:{"click":function($event){return _vm.viewMaterial(data.id)}}},[_c('div',{staticClass:"material_type_box"},[_c('div',{staticClass:"subcategory_box"}),_vm._v(" "+_vm._s(data.name)+" ")]),(_vm.categoryId == data.id)?_c('div',{staticClass:"operation_module_box"},[_c('i',{staticClass:"el-icon-edit",on:{"click":function($event){$event.stopPropagation();return _vm.cikAdd(-1, data, '', true)}}}),(data.status == 1)?_c('i',{staticClass:"el-icon-circle-close",on:{"click":function($event){$event.stopPropagation();return _vm.deleteMaterial(data.id)}}}):_vm._e()]):_vm._e()])}),0):_vm._e()])}),0):_vm._e()])}),0):_c('div',{staticClass:"no_data_available"},[_vm._v("暂无图片~")])]),_c('div',{staticClass:"right_template_box"},[(
          _vm.addtype != -2 && _vm.isViewMaterial == false && _vm.isMaterialAdd == false
        )?_c('category',{attrs:{"managerId":_vm.managerId,"addtype":_vm.addtype,"categoryData":_vm.categoryData},on:{"updateList":_vm.getImagesTypeList}}):_vm._e(),(_vm.isViewMaterial)?_c('image_List',{attrs:{"categoryId":_vm.categoryId},on:{"addMaterial":_vm.addMaterial,"editMaterial":_vm.editMaterial}}):_vm._e(),(_vm.isMaterialAdd)?_c('imageAdd',{attrs:{"categoryId":_vm.categoryId,"materialListId":_vm.materialListId,"checkMsg":_vm.checkMsg},on:{"returnList":_vm.returnList}}):_vm._e()],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }