import { render, staticRenderFns } from "./materialCategory.vue?vue&type=template&id=296dd051&scoped=true"
import script from "./materialCategory.vue?vue&type=script&lang=js"
export * from "./materialCategory.vue?vue&type=script&lang=js"
import style0 from "./materialCategory.vue?vue&type=style&index=0&id=296dd051&prod&rel=stylesheet%2Fscss&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "296dd051",
  null
  
)

export default component.exports