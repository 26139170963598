module.exports = {
  getoss: "/device/system/v1/web/admin/oss/sts",
  login: "/device/system/v1/web/admin/user/login", // 登录
  getDistributorNPoList: "/device/distributor/v1/web/admin/distributor/po/list", // 分销商列表(多级，不受权限控制)
  shopList: "/device/flexible/v1/web/admin/u/p/shop/page", // 门店列表
  getGoodsItemPoList: "/device/goods/v1/web/admin/goods/po/item/list", // 根据搜索条件查找货品列表（不受权限控制）
  //设备接口
  addEquipmentType: "/device/v1/web/admin/device/category/add", //添加设备类型
  getDevicetypeList: "/device/v1/web/admin/device/category/list", //获取设备类型列表
  getDevicetypedetails: "/device/v1/web/admin/device/category/detail", //获取设备类型详情
  updateDevicetypedetails: "/device/v1/web/admin/device/category/update", //更新设备类型详情
  deleteDevicetypedetails: "/device/v1/web/admin/device/category/delete", //删除设备类型
  addDevice: "/device/v1/web/admin/device/add", //新增设备
  getDeviceList: "/device/v1/web/admin/device/list", //设备列表
  getDevicedetails: "/device/v1/web/admin/device/detail", //获取设备详情
  updateDevicedetails: "/device/v1/web/admin/device/update", //更新设备详情
  deleteDevicedetails: "/device/v1/web/admin/device/delete", //删除设备
  openDevicedetails: "/device/v1/web/admin/device/open", //禁用启用设备
  resetDevice: "/device/v1/web/admin/device/reset", //重置设备
  adjustmentDevice: "/device/v1/web/admin/device/frequency/change", //调整设备次数
  getDevicevlog: "/device/v1/web/admin/device/record", //查看日志
  DevicevtempDownLoad: "device/v1/web/admin/device/import/tempDownLoad", // 设备模板导出
  ImportDownLoad: "device/v1/web/admin/device/import", // 设备模板导入
  ImportfrequencyDownLoad:
    "device/v1/web/admin/device/frequency/import/tempDownLoad", // 设备次数模板导出
  DevicevfrequencyDownLoad: "device/v1/web/admin/device/frequency/import", // 设备次数模板导入
  addFilmtype: "/device/v1/web/admin/film/category/add", // 新增膜品类型
  getFilmtypeList: "/device/v1/web/admin/film/category/list", // 获取膜品类型列表
  getFilmtypeDetail: "/device/v1/web/admin/film/category/detail", // 获取膜品类型详情
  updateFilmtypeDetail: "/device/v1/web/admin/film/category/update", // 修改膜品类型详情
  deleteFilmtypeDetail: "/device/v1/web/admin/film/category/delete", // 删除膜品类型
  generateMembraneCode: "/device/v1/web/admin/film/create", // 生成膜码
  getMembraneCodeList: "/device/v1/web/admin/film/list", // 获取膜码列表
  MembraneCodeOpen: "/device/v1/web/admin/film/open", // 启用禁用膜码
  deleteMembraneCodeOpen: "/device/v1/web/admin/film/delete", // 删除膜码
  exchangeMembraneCode: "/device/v1/web/admin/film/exchange", // 兑换膜码
  exporttempDownLoad: "/device/v1/web/admin/film/import/tempDownLoad", // 导出膜码模板
  ImporttempDownLoad: "/device/v1/web/admin/film/import", // 导入膜码
  addMembranePackage: "/device/v1/web/admin/cut/add", // 添加套餐
  getMembranePackageList: "/device/v1/web/admin/cut/list", // 获取套餐列表
  membranePackageopen: "/device/v1/web/admin/cut/open", // 启用禁用套餐
  deleteMembranePackageopen: "/device/v1/web/admin/cut/delete", // 删除套餐
  getMembranePackagedetail: "/device/v1/web/admin/cut/detail", // 获取套餐详情
  updateMembranePackagedetail: "/device/v1/web/admin/cut/update", // 修改套餐详情
  getTimesList: "/device/v1/web/admin/times/list", // 次数管理列表
  getTimesRecord: "/device/v1/web/admin/times/record", // 次数管理日志
  exportMembraneCode: "/device/v1/web/admin/film/export", // 导出膜码
  exportPrintCode: "/device/v1/web/admin/film/print/export", // 导出打印码
  bindingdistribution: "/device/v1/web/admin/film/distribution", // 膜码绑定分销商
  unbindMembraneCode: "/device/v1/web/admin/film/distribution/reset", //膜码解绑分销商
  setMode: "/device/v1/web/admin/device/cutCoupon", //设置切膜模式
  exportDevice: "/device/v1/web/admin/device/export", //导出设备
  adjustMembraneCodeType: "/device/v1/web/admin/film/category/change", //调整膜码类型
  serDevicedefault: "/device/v1/web/admin/device/distributor/default", //分销商默认设备设置
  getDeviceOrderList: "/device/v1/web/admin/cut/records/list", //获取设备切膜记录
  exportOrderrecords: "/device/v1/web/admin/cut/records/export", //导出切膜订单记录
  getChannelList: "/device/v1/web/admin/cut/records/channel/list", //获取渠道列表
  getChannelDetails: "/device/v1/web/admin/cut/records/channel/detail", //获取渠道详情
  updateChannelDetails: "/device/v1/web/admin/cut/records/channel/update", //更新渠道配置
  addChannelDetails: "/device/v1/web/admin/cut/records/channel/add", //新增渠道配置
  deleteChannelDetails: "/device/v1/web/admin/cut/records/channel/delete", //删除渠道配置
  getRelevanceList: "/device/v1/web/admin/cut/records/category/relevance/list", //第三方映射分类列表
  deleteRelevanceList:
    "/device/v1/web/admin/cut/records/category/relevance/delete", //删除第三方映射分类
  addRelevanceList: "/device/v1/web/admin/cut/records/category/relevance/add", //添加分类映射
  updateRelevanceList:
    "/device/v1/web/admin/cut/records/category/relevance/update", //更新分类映射
  getRelevanceDetail:
    "/device/v1/web/admin/cut/records/category/relevance/detail", //获取分类映射详情
  synchronizer: "/device/v1/web/admin/cut/records/sync", //手动同步切膜记录
  syncDevicehronizer: "/device/v1/web/admin/device/telecom/sync", //同步设备
  synchronizeDeviceRecords: "/device/v1/web/admin/device/telecom/sync/log", //同步设备记录
  exportSynchronizeddevicerecords:
    "/device/v1/web/admin/device/export/telecom/sync/log", //导出同步设备记录

  //定制接口
  getDiyOrderList: "/samsung_api/admin/api/v1/order/list", //获取定制订单列表
  sendOutGoods: "/samsung_api/admin/api/v1/order/deliver", //发货
  getDiyOrderDetails: "/samsung_api/admin/api/v1/order/info", //获取定制订单详情
  modifyOrderStatus: "/samsung_api/admin/api/v1/order/syncOrderStatus", //修改订单状态

  //材料接口
  getMaterialCategorylist: "/device/v1/web/admin/device/material/category/list", //获取材料分类树
  deleteMaterialCategory:
    "/device/v1/web/admin/device/material/category/delete", //删除材质类型
  addMaterialCategory: "/device/v1/web/admin/device/material/category/add", //新增材质类型
  updateMaterialCategory:
    "/device/v1/web/admin/device/material/category/update", //修改材质类型
  getMaterialList: "/device/v1/web/admin/device/material/list", //获取材料列表
  addMaterialList: "/device/v1/web/admin/device/material/add", //新增材料
  updateMaterialList: "/device/v1/web/admin/device/material/update", //更新材质
  batchupdateMaterialList: "/device/v1/web/admin/device/material/update/batch", //批量更新材质
  deleteMaterialList: "/device/v1/web/admin/device/material/delete", //删除材质
  batchdeleteMaterialList: "/device/v1/web/admin/device/material/delete/batch", //批量删除材质
  getMaterialdetails: "/device/v1/web/admin/device/material/detail", //获取材料详情
  batchEnableDisableMaterial:
    "/device/v1/web/admin/device/material/update/batch/status", //批量启禁用

  //优惠券需要接口（暂无）
  productUsableList: "/flexible/v1/web/admin/u/po/productCategory/listUsable", // 产品类型下拉列表
  materialLowestList: "/flexible/v1/web/admin/u/po/material/listLowest", // 最底级材质列表
  modelAllList: "/flexible/v1/web/admin/u/p/model/pageSimpleCOByCondition", // 非树形所有型号列表
  modelPoList: "/flexible/v1/web/admin/u/po/model/page", // 型号列表(不受权限控制)

  //优惠券
  couponList: "/device/promotion/v1/web/admin/coupon/list", // 优惠券列表
  addCoupon: "/device/promotion/v1/web/admin/coupon", // 优惠券添加
  updateCoupon: "/device/promotion/v1/web/admin/coupon", //更新优惠券
  couponStatus: "/device/promotion/v1/web/admin/coupon/status", // 优惠券状态更改
  couponCount: "/device/promotion/v1/web/admin/coupon/count", // 修改优惠券发放总数量和限购数量
  couponNoList: "/device/promotion/v1/web/admin/coupon/no", // 券码列表
  couponNoStatus: "/device/promotion/v1/web/admin/coupon/customer/status", // 根据券码变更客户优惠券状态
  manualOperationCoupon:
    "/device/promotion/v1/web/admin/coupon/manual/operation", // 手动批量发券

  // 框图
  getBorderimageslist: "/device/v1/web/admin/border/images/list", //框图列表
  addBorderimageslist: "/device/v1/web/admin/border/images/add", //新增框图
  batchupdateBorderimageslist:
    "/device/v1/web/admin/border/images/batch/update", //批量修改图片
  batchudeleteBorderimageslist:
    "/device/v1/web/admin/border/images/batch/delete", //批量删除图片
  updateBorderimageslist: "/device/v1/web/admin/border/images/update", //更新框图
  deleteBorderimageslist: "/device/v1/web/admin/border/images/batch/delete", //删除框图

  //型号列表
  deleteModelType: "/device/v1/web/admin/device/model/category/delete", //删除型号分类
  getModelTypelist: "/device/v1/web/admin/device/model/category/list", //型号分类列表
  updateModel: "/device/v1/web/admin/device/model/category/update", //更新型号分类信息
  addModel: "/device/v1/web/admin/device/model/category/add", //新增型号分类信息
  getModellist: "/device/v1/web/admin/device/model/list", //型号列表
  deleteModellist: "/device/v1/web/admin/device/model/delete", //删除设备型号
  batchdeleteModellist: "/device/v1/web/admin/device/model/delete/batch", //批量删除设备型号
  enableDisableModel: "/device/v1/web/admin/device/model/update/batch", //启用/禁用
  addModellist: "/device/v1/web/admin/device/model/add", //添加型号
  updateModeldetails: "/device/v1/web/admin/device/model/update", //更新型号详情
  getModellistdetails: "/device/v1/web/admin/device/model/detail", //查询型号详情

  //图片列表
  getImagesTypeList: "/device/v1/web/admin/device/images/category/list", //获取图片分类
  deleteImagesType: "/device/v1/web/admin/device/images/category/delete", //删除图片分类
  addImagesType: "/device/v1/web/admin/device/images/category/add", //新增图片分类
  updateImagesType: "/device/v1/web/admin/device/images/category/update", //更新图片分类
  getImagesList: "/device/v1/web/admin/device/images/list", //获取图片列表
  deleteImagesList: "/device/v1/web/admin/device/images/delete", //删除图片
  batchdeleteImagesList: "/device/v1/web/admin/device/images/delete/batch", //批量删除图片
  updateImagesList: "/device/v1/web/admin/device/images/update", //修改图片
  batchupdateImagesList: "/device/v1/web/admin/device/images/update/batch", //批量修改图片
  addImagesList: "/device/v1/web/admin/device/images/add", //新增图片
  getImagesListdetails: "/device/v1/web/admin/device/images/detail", //获取图片详情

  //店铺接口
  getShopList: "/device/flexible/v1/web/admin/u/p/shop/page", //店铺列表
  storeActivationDeactivation:
    "/device/flexible/v1/web/admin/u/p/shop/updateOpenFlag", //店铺启禁用
  deleteStore: "/device/flexible/v1/web/admin/u/p/shop", //删除店铺
  editShop: "/device/flexible/v1/web/admin/u/p/shop", //修改店铺
  addShop: "/device/flexible/v1/web/admin/u/p/shop", //新增店铺
  shopDetail: "/device/flexible/v1/web/admin/u/p/shop/detailById", //店铺详情
  exportStoreTemplate:
    "/device/flexible/v1/web/admin/u/p/importShop/tempDownLoad", //导出门店模板
  storeImport: "/device/flexible/v1/web/admin/u/p/shop/import", //门店导入
  regionListById: "/device/system/v1/web/admin/region/list",
};
