<template>
  <div>
    <div class="material_title_box">
      {{
        addtype == 0
          ? "新增总分类"
          : addtype == 1
          ? "新增子分类"
          : "编辑分类信息"
      }}
    </div>
    <div class="material_linq"></div>
    <div class="material_form_box">
      <el-form
        :model="formData"
        :rules="rules"
        label-width="20%"
        label-position="right"
        ref="ruleForm"
      >
        <el-form-item label="分类ID" prop="id" v-if="addtype != 0">
          {{ formData.id }}
        </el-form-item>
        <el-form-item label="材料分类名称" prop="name">
          <el-input v-model="formData.name" placeholder="请输入材料名称" />
        </el-form-item>
        <el-form-item label="材料编码" prop="code">
          <el-input v-model="formData.code" placeholder="请输入材料编码" />
        </el-form-item>
        <el-form-item label="材料描述" prop="description">
          <el-input
            v-model="formData.description"
            placeholder="请输入材料描述"
          />
        </el-form-item>
        <el-form-item label="图片" prop="description">
          <el-upload
            :action="action"
            :headers="headers"
            list-type="picture-card"
            :limit="1"
            :on-exceed="handleExceed"
            :on-remove="handleRemove"
            :before-upload="beforeUpload"
            :file-list="fileList"
          >
            <i slot="default" class="el-icon-plus"></i>
          </el-upload>
        </el-form-item>
        <el-form-item label="状态" prop="status" style="margin-bottom: 0px">
          <el-radio-group v-model="formData.status" size="mini">
            <el-radio :label="1">启用</el-radio>
            <el-radio :label="0">禁用</el-radio>
          </el-radio-group>
        </el-form-item>
      </el-form>
    </div>
    <div class="button_box">
      <div class="exchange_bit" @click="submitData()">保存</div>
      <div class="return_bit" v-if="addtype != 0" @click="deleteMaterial">
        删除
      </div>
    </div>
  </div>
</template>
<script src="https://gosspublic.alicdn.com/aliyun-oss-sdk-4.4.4.min.js"></script>
<script>
import { monthDay } from "@/utils/common";
import api from "@/api/allUrl";
import axios from "axios";
export default {
  props: ["managerId", "addtype", "categoryData"],
  name: "materialList_category",
  data() {
    return {
      action:
        process.env.VUE_APP_API_URL + "/device/system/v1/web/admin/oss/sts",
      headers: {
        Accept: "application/json",
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        Platform: "GF60004",
        Version: "1.0.0",
        token: localStorage.getItem("token"),
        "Cache-Control": "no-cache",
        Pragma: "no-cache",
      },
      fileList: [],
      buttonTime: true,
      formData: {
        childrenCount: 1,
        id: "",
        imgUrl: "",
        isLeaf: false,
        name: "",
        status: 1,
        code: "",
        description: "",
        parentId: "",
      },
      rules: {
        name: [
          { required: true, message: "请输入材料分类名称", trigger: "blur" },
          { max: 100, message: "最多展示100个字", trigger: "blur" },
        ],
      },
    };
  },
  watch: {
    categoryData(val) {
      if (val.id != undefined) {
        this.formData = val;
        this.fileList = [];
        if (this.formData.imageUrl != undefined) {
          this.fileList = [{ name: "food.jpeg", url: this.formData.imageUrl }];
        }
      } else {
        this.formData = {
          imgUrl: "",
          childrenCount: 1,
          id: "",
          isLeaf: false,
          name: "",
          status: 1,
        };
      }
    },
  },
  activated() {
    if (this.categoryData.id != undefined) {
      this.fileList = [];
      this.formData = this.categoryData;
      if (this.formData.imageUrl != undefined) {
        this.fileList = [{ name: "food.jpeg", url: this.formData.imageUrl }];
      }
    }
  },
  methods: {
    submitData() {
      this.$refs["ruleForm"].validate((valid) => {
        if (!valid) {
          return false;
        } else {
          if (this.buttonTime) {
            delete this.formData.children;
            this.buttonTime = false;

            if (this.formData.id == "") {
              if (this.addtype == 0) {
                delete this.formData.parentId;
              } else {
                this.formData.parentId = this.managerId;
              }
              delete this.formData.id;
              this.$api
                .post(this, api.addMaterialCategory, this.formData)
                .then(() => {
                  this.$message({
                    message: "新增成功",
                  });
                  this.buttonTime = true;
                  this.$emit("updateList");
                });
            } else {
              this.$api
                .put(this, api.updateMaterialCategory, this.formData)
                .then(() => {
                  this.$message({
                    message: "更新成功",
                  });
                  this.buttonTime = true;
                  this.$emit("updateList");
                });
            }
          }
        }
      });
    },
    deleteMaterial() {
      this.$confirm("此操作将删除改材质类型，是否继续？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
        center: true,
      })
        .then(() => {
          this.$api
            .delete(this, api.deleteMaterialCategory, { id: this.formData.id })
            .then(() => {
              this.$message({
                message: "删除成功",
              });
              this.$emit("updateList");
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消操作",
          });
        });
    },
    handleRemove() {
      this.formData.imageUrl = "";
      this.fileList = [];
    },
    beforeUpload(file) {
      if (
        file.type != "image/jpeg" &&
        file.type != "image/bmp" &&
        file.type != "image/jpg" &&
        file.type != "image/jpeg" &&
        file.type != "image/png" &&
        file.type != "image/gif"
      ) {
        this.$message.error("上传图片只能是bmp、jpg、jpeg、png、gif格式!");
        return false;
      }
      const isLt2M = file.size / 1024 / 1024 < 5;
      if (!isLt2M) {
        this.$message.error("上传图片大小不能超过 5MB!");
        return isLt2M;
      }
      const _self = this;
      // 随机命名
      let random_name =
        _self.random_string(6) +
        "_" +
        new Date().getTime() +
        "." +
        file.name.split(".").pop();

      axios({
        method: "get",
        url:
          process.env.VUE_APP_API_URL + "/device/system/v1/web/admin/oss/sts",
        headers: this.headers,
        withCredentials: false,
      }).then((result) => {
        const client = new OSS.Wrapper({
          region: result.data.data.region,
          accessKeyId: result.data.data.accessKeyId,
          accessKeySecret: result.data.data.accessKeySecret,
          stsToken: result.data.data.securityToken,
          bucket: result.data.data.bucketName,
          endpoint: result.data.data.endpoint,
          secure: true,
        });
        // 上传
        client
          .multipartUpload(
            "goods/" + monthDay(new Date()) + "/" + random_name,
            file,
            {}
          )
          .then((results) => {
            return new Promise((resolve) => {
              this.formData.imgUrl = result.data.data.hostname + results.name;
              resolve(true);
            });
          })
          .catch((err) => {
            console.log(err);
          });
      });
    },

    handleExceed(files, fileList) {
      if (files.length + fileList.length > 1) {
        this.$message.error("最多上传1个文件");
        return false;
      }
    },
    // 随机生成文件名
    random_string(len) {
      len = len || 32;
      var chars = "ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678";
      var maxPos = chars.length;
      var pwd = "";
      for (let i = 0; i < len; i++) {
        pwd += chars.charAt(Math.floor(Math.random() * maxPos));
      }
      return pwd;
    },
  },
};
</script>

<style rel="stylesheet/scss" scoped>
/deep/.el-upload-list--picture-card .el-upload-list__item {
  width: 50px;
  height: 50px;
}
</style>
