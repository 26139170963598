<template>
  <div>
    <div class="page_header_title_box" v-if="isShow">
      <i class="el-icon-back" @click="handleCancel"></i>
      新增套餐
    </div>
    <div class="page_header_title_box" v-else>
      <i class="el-icon-back" @click="handleCancel"></i>套餐详情
    </div>
    <div style="padding: 70px 20px 20px 20px">
      <div class="content_box">
        <div class="tip-left">
          <p><code>基本信息</code></p>
        </div>
        <el-form
          label-width="150px"
          :rules="rules"
          ref="formData"
          :model="formData"
          v-loading="vloading"
        >
          <el-form-item label="套餐名称" prop="name">
            <el-input
              size="mini"
              style="width: 300px"
              v-model="formData.name"
              placeholder="不超过40个字"
              maxlength="40"
            >
            </el-input>
          </el-form-item>
          <el-form-item label="套餐说明">
            <el-input
              style="width: 50%"
              size="mini"
              type="textarea"
              :rows="4"
              v-model="formData.remark"
              placeholder="不超过200个字"
              maxlength="200"
            ></el-input>
          </el-form-item>
          <el-form-item label="排序" prop="sort">
            <el-input
              size="mini"
              style="width: 300px"
              v-model="formData.sort"
              placeholder="不超过40个字"
              maxlength="40"
            >
            </el-input>
          </el-form-item>
          <el-form-item label="状态">
            <el-radio-group v-model="formData.openFlag">
              <el-radio :label="1">启用</el-radio>
              <el-radio :label="0">禁用</el-radio>
            </el-radio-group>
          </el-form-item>
          <div class="tip-left">
            <p><code>详细信息</code></p>
          </div>
          <el-form-item label="套餐形式">
            <el-radio-group v-model="formData.type">
              <el-radio :label="1">按次数</el-radio>
              <el-radio :label="2">按天数</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="天/次数" prop="countOrDay">
            <el-input
              size="mini"
              style="width: 300px"
              v-model="formData.countOrDay"
              placeholder="请输入整数数字"
              maxlength="40"
            >
            </el-input>
          </el-form-item>
          <!-- <el-form-item label="单价（元）" prop="name">
                <el-input size="mini" style="width:300px;" v-model="formData.price" placeholder="请输入套餐价格"
                    maxlength="40">
                </el-input>
            </el-form-item> -->

          <el-form-item label="关联货品" prop="itemCodes">
            <el-button class="mini-search-btn" @click="itemsSelectShow()"
              >新增</el-button
            >
            <div style="width: 800px">
              <el-table
                :data="goodsItems"
                border
                header-row-class-name="header-row"
                class="tableCenter"
              >
                <el-table-column
                  label="货品编码"
                  align="center"
                  prop="itemCode"
                  width="180"
                  fixed
                ></el-table-column>
                <el-table-column
                  label="货品名称"
                  align="center"
                  prop="itemName"
                  width="300"
                  fixed
                ></el-table-column>
                <el-table-column
                  label="上架状态"
                  align="center"
                  prop="saleStatus"
                  :formatter="formatSaleStatus"
                  width="180"
                ></el-table-column>
                <el-table-column
                  label="操作"
                  width="100"
                  align="center"
                  fixed="right"
                >
                  <template slot-scope="scope">
                    <el-button
                      class="mini-delete-btn"
                      @click="handleDeleteGoodsItems(scope.$index)"
                      >删除
                    </el-button>
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </el-form-item>
          <el-form-item label="可视范围">
            <el-radio-group v-model="formData.distributorScope">
              <el-radio :label="1">全部分销商</el-radio>
              <el-radio :label="3">指定分销商</el-radio>
            </el-radio-group>
            <el-button
              class="mini-search-btn"
              v-if="formData.distributorScope == 3"
              @click="distributorShow = true"
              >选择分销商</el-button
            >
            <div style="width: 550px" v-if="formData.distributorScope == 3">
              <el-table
                :data="distributorData"
                header-row-class-name="header-row"
                max-height="600"
                class="tableCenter"
              >
                <el-table-column
                  align="center"
                  label="分销商ID"
                  width="120"
                  prop="id"
                ></el-table-column>
                <el-table-column
                  align="center"
                  label="分销商名称"
                  width="320"
                  prop="companyName"
                ></el-table-column>
                <el-table-column align="center" label="操作">
                  <template slot-scope="scope">
                    <el-button
                      style="margin-top: 0px; margin-bottom: 0px"
                      class="mini-delete-btn"
                      @click="handleDeleteNoDistributor(scope.$index)"
                    >
                      删除
                    </el-button>
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </el-form-item>
          <el-form-item label="不可视范围">
            <el-radio-group v-model="formData.distributorScopeNo">
              <el-radio :label="0">不指定</el-radio>
              <el-radio :label="3">指定分销商</el-radio>
            </el-radio-group>
            <el-button
              class="mini-search-btn"
              v-if="formData.distributorScopeNo == 3"
              @click="distributorNoShow = true"
              >选择分销商</el-button
            >
            <div style="width: 550px" v-if="formData.distributorScopeNo == 3">
              <el-table
                :data="distributorNoData"
                header-row-class-name="header-row"
                max-height="600"
                class="tableCenter"
              >
                <el-table-column
                  align="center"
                  label="分销商ID"
                  width="120"
                  prop="id"
                ></el-table-column>
                <el-table-column
                  align="center"
                  label="分销商名称"
                  width="320"
                  prop="companyName"
                ></el-table-column>
                <el-table-column align="center" label="操作">
                  <template slot-scope="scope">
                    <el-button
                      style="margin-top: 0px; margin-bottom: 0px"
                      class="mini-delete-btn"
                      @click="handleDeleteDistributor(scope.$index)"
                    >
                      删除
                    </el-button>
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </el-form-item>
        </el-form>
        <el-dialog
          title="添加货品"
          :modal-append-to-body="false"
          :close-on-click-modal="false"
          :visible="itemsShow"
          width="80%"
          :before-close="closehandleShow"
        >
          <selectItem
            v-if="itemsShow"
            :selectItemsData="goodsItems"
            :saleStatus="3"
            :goodsType="4"
            :multipleChoice="true"
            ref="selectGoodItems"
            @cancel="itemsShow = false"
            @submit="getItemsData"
          ></selectItem>
        </el-dialog>
        <el-dialog
          :modal-append-to-body="false"
          :visible="distributorShow"
          :before-close="disCancel"
          width="80%"
        >
          <selectDistributor
            :distributorData="distributorData"
            ref="selectDistributor"
            @cancel="cancel"
            @submit="disSubmit"
          >
          </selectDistributor>
        </el-dialog>
        <el-dialog
          :modal-append-to-body="false"
          :visible="distributorNoShow"
          :before-close="disNoCancel"
          width="80%"
        >
          <selectDistributor
            :distributorData="distributorNoData"
            ref="selectNoDistributor"
            @cancel="noCancel"
            @submit="disNoSubmit"
          >
          </selectDistributor>
        </el-dialog>
        <div class="clearfix footbtn">
          <div class="clearfix footbtn">
            <el-button
              type="primary"
              :loading="bloading"
              style="margin-left: 46.5%"
              @click="handleSubmit()"
              >保存</el-button
            >
            <el-button @click="handleCancel()">返回</el-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import selectItem from "@/components/selectItem";
import selectDistributor from "@/components/distributorList";
import api from "@/api/allUrl";
export default {
  name: "add_package",
  components: {
    selectItem,
    selectDistributor,
  },
  data() {
    return {
      formData: {
        name: "",
        remark: "",
        sort: 1,
        openFlag: 1,
        type: 1,
        countOrDay: 1,
        // price: 1,
        itemCodes: [],
        distributorScope: 1,
        distributorScopeNo: 0,
        distributorIds: [],
        distributorNoIds: [],
      },
      distributorData: [],
      distributorNoData: [],
      goodsItems: [],
      itemsShow: false,
      distributorShow: false,
      distributorNoShow: false,
      isShow: true,
      vloading: false,
      bloading: false,
      rules: {
        name: [
          {
            required: true,
            message: "请输入套餐名称",
            trigger: "blur",
          },
        ],
        sort: [
          {
            required: true,
            message: "请输入套餐排序",
            trigger: "blur",
          },
        ],
        countOrDay: [
          {
            required: true,
            message: "请输入次数或天数",
            trigger: "blur",
          },
        ],
        // price: [{
        //     required: true,
        //     message: '请输入套餐价格',
        //     trigger: 'blur'
        // }],
        itemCodes: [
          {
            required: true,
            message: "请选择关联货品",
            trigger: "blur",
          },
        ],
      },
    };
  },
  activated() {
    this.formData = {
      name: "",
      remark: "",
      sort: 1,
      openFlag: 1,
      type: 1,
      countOrDay: 1,
      // price: 1,
      itemCodes: [],
      distributorScope: 1,
      distributorScopeNo: 0,
      distributorIds: [],
      distributorNoIds: [],
    };
    this.distributorData = [];
    this.distributorNoData = [];
    this.goodsItems = [];
    this.itemsShow = false;
    this.distributorShow = false;
    this.distributorNoShow = false;
    this.isShow = true;
    this.vloading = false;
    this.bloading = false;

    if (this.$route.query.checkMsg == 3) {
      this.isShow = false;
      this.vloading = true;
      this.getMembranePackagedetail();
    } else {
      this.isShow = true;
    }
  },
  methods: {
    getMembranePackagedetail() {
      this.$api
        .get(this, api.getMembranePackagedetail, {
          id: this.$route.query.id,
        })
        .then((res) => {
          if (res.success) {
            this.formData = {
              name: res.data.name,
              remark: res.data.remark,
              sort: res.data.sort,
              openFlag: res.data.openFlag,
              type: res.data.type,
              countOrDay: res.data.countOrDay,
              // price: res.data.price,
              id: res.data.id,
              itemCodes: [],
              distributorScope: res.data.distributorScope,
              distributorScopeNo: res.data.distributorScopeNo,
              distributorIds: [],
              distributorNoIds: [],
            };

            this.goodsItems = res.data.goodsItems;
            this.goodsItems.forEach((item) => {
              this.formData.itemCodes.push(item.itemCode);
            });
            if (res.data.distributorNos != undefined) {
              this.distributorNoData = res.data.distributorNos;
              this.distributorNoData.forEach((item) => {
                this.formData.distributorNoIds.push(item.id);
              });
            }
            if (res.data.distributors != undefined) {
              this.distributorData = res.data.distributors;
              this.distributorData.forEach((item) => {
                this.formData.distributorIds.push(item.id);
              });
            }
          }
          this.vloading = false;
        });
    },
    // 必填验证
    handleSubmit() {
      this.dloading = true;
      this.$refs["formData"].validate((valid) => {
        if (valid) {
          if (this.$route.query.id == undefined) {
            this.$api
              .post(this, api.addMembranePackage, this.formData)
              .then((res) => {
                if (res.success) {
                  this.$message.success({
                    message: "新增套餐成功",
                    duration: 3 * 1000,
                  });
                  this.handleCancel();
                }
                this.dloading = false;
              });
          } else {
            this.$api
              .put(this, api.updateMembranePackagedetail, this.formData)
              .then((res) => {
                if (res.success) {
                  this.$message.success({
                    message: "套餐修改成功",
                    duration: 3 * 1000,
                  });
                  this.handleCancel();
                }
                this.dloading = false;
              });
          }
        } else {
          this.$message({
            message: "请先将套餐信息补充完整",
            type: "error",
            duration: 3 * 1000,
            onClose: () => {},
          });
          this.dloading = false;
        }
      });
    },
    disNoSubmit(msg) {
      this.distributorNoData = msg;
      this.formData.distributorNoIds = [];
      msg.forEach((item) => {
        this.formData.distributorNoIds.push(item.id);
      });
      this.noCancel();
    },
    disSubmit(msg) {
      this.distributorData = msg;
      this.formData.distributorIds = [];
      msg.forEach((item) => {
        this.formData.distributorIds.push(item.id);
      });
      this.cancel();
    },
    cancel() {
      this.distributorShow = false;
    },
    noCancel() {
      this.distributorNoShow = false;
    },
    disCancel() {
      this.$refs.selectDistributor.handleCancel();
    },
    disNoCancel() {
      this.$refs.selectNoDistributor.handleCancel();
    },
    handleDeleteDistributor(index) {
      this.distributorData.splice(index, 1);
      this.formData.distributorIds.splice(index, 1);
    },
    handleDeleteNoDistributor(index) {
      this.distributorNoData.splice(index, 1);
      this.formData.distributorNoIds.splice(index, 1);
    },
    // 获取选中货品
    getItemsData(val) {
      this.formData.itemCodes = [];
      val.forEach((item) => {
        this.formData.itemCodes.push(item.itemCode);
      });
      this.goodsItems = val;
      this.itemsShow = false;
    },
    // 删除货品
    handleDeleteGoodsItems(index) {
      this.goodsItems.splice(index, 1);
      this.formData.itemCodes.splice(index, 1);
    },
    closehandleShow() {
      this.itemsShow = false;
    },
    itemsSelectShow() {
      this.itemsShow = true;
    },
    formatSaleStatus(row, col, val) {
      switch (val) {
        case 1:
          return "未上架";
        case 2:
          return "审批中";
        case 3:
          return "已上架";
      }
    },
    handleCancel() {
      // 返回操作
      this.$router.go(-1);
    },
  },
};
</script>
<style lang="scss" scoped></style>
