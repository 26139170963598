<template>
  <div>
    <div class="page_header_title_box" v-if="isShow">
      <i class="el-icon-back" @click="handleCancel"></i>
      添加设备类型
    </div>
    <div class="page_header_title_box" v-else>
      <i class="el-icon-back" @click="handleCancel"></i>编辑设备类型
    </div>
    <div style="padding: 70px 20px 20px 20px">
      <div class="content_box">
        <el-form
          label-width="150px"
          :rules="rules"
          ref="formData"
          :model="formData"
          v-loading="vloading"
        >
          <el-form-item label="设备类型名称" prop="name">
            <el-input
              size="mini"
              style="width: 300px"
              v-model="formData.name"
              placeholder="不超过40个字"
              maxlength="40"
            >
            </el-input>
          </el-form-item>
          <el-form-item label="第三方编码" prop="thirdPartyCode">
            <el-input
              size="mini"
              style="width: 300px"
              v-model="formData.thirdPartyCode"
              placeholder="不超过40个字"
              maxlength="40"
            >
            </el-input>
          </el-form-item>
          <el-form-item label="设备类型描述">
            <el-input
              style="width: 50%"
              size="mini"
              type="textarea"
              :rows="4"
              v-model="formData.remark"
              placeholder="不超过200个字"
              maxlength="200"
            ></el-input>
          </el-form-item>
          <el-form-item label="设备状态">
            <el-radio-group v-model="formData.openFlag">
              <el-radio :label="1">启用</el-radio>
              <el-radio :label="0">禁用</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="关联货品" prop="itemCodes">
            <el-select
              class="mini_select_bit"
              size="mini"
              v-model="formData.itemCodes"
              placeholder="请选择"
              style="width: 180px; margin-right: 5px"
              clearable
            >
              <el-option
                label="Jakit T1000 智能切膜机"
                value="8030200000005"
              ></el-option>
              <el-option
                label="Jakit T2000 智能切膜机"
                value="8030200000004"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-form>
        <div class="clearfix footbtn">
          <el-button
            class="mini-search-btn"
            :loading="dloading"
            style="margin-left: 46.5%"
            @click="handleSubmit()"
            >保存</el-button
          >
          <el-button size="mini" @click="handleCancel()">返回</el-button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import api from "@/api/allUrl";
export default {
  name: "add_devicetype",
  data() {
    return {
      isShow: true,
      vloading: false,
      dloading: false,
      formData: {
        name: "",
        openFlag: 1, //状态, 1.启用 0.禁用
        remark: "",
        itemCodes: "",
      },
      rules: {
        name: [
          {
            required: true,
            message: "请输入设备名称",
            trigger: "blur",
          },
        ],
        itemCodes: [
          {
            required: true,
            message: "请选择关联货品",
            trigger: "blur",
          },
        ],
      },
    };
  },
  activated() {
    this.formData = {
      name: "",
      openFlag: 1, //状态, 1.启用 0.禁用
      remark: "",
      itemCodes: "",
    };
    if (this.$route.query.checkMsg == 3) {
      this.vloading = true;
      this.isShow = false;
      this.getDevicetypedetails();
    } else {
      this.isShow = true;
    }
  },
  methods: {
    //获取设备类型详情
    getDevicetypedetails() {
      this.$api
        .get(this, api.getDevicetypedetails, {
          id: this.$route.query.id,
        })
        .then((res) => {
          this.formData.name = res.data.name;
          this.formData.openFlag = res.data.openFlag;
          this.formData.remark = res.data.remark;
          this.formData.itemCodes =
            res.data.itemCodes == undefined ? "" : res.data.itemCodes[0];
          this.vloading = false;
        });
    },
    // 必填验证
    handleSubmit() {
      this.dloading = true;
      this.$refs["formData"].validate((valid) => {
        var data = this.formData;
        data.itemCodes = [this.formData.itemCodes];
        if (valid) {
          if (this.isShow) {
            this.$api
              .post(this, api.addEquipmentType, this.formData)
              .then((res) => {
                if (res.success) {
                  this.$message.success({
                    message: "新增设备类型成功",
                    duration: 3 * 1000,
                  });
                  this.handleCancel();
                }
                this.dloading = false;
              });
          } else {
            this.updateDevicetypedetails();
          }
        } else {
          this.$message({
            message: "请先将设备类型信息补充完整",
            type: "error",
            duration: 3 * 1000,
            onClose: () => {},
          });
          this.dloading = false;
        }
      });
    },
    updateDevicetypedetails() {
      this.formData.id = this.$route.query.id;
      this.$api
        .put(this, api.updateDevicetypedetails, this.formData)
        .then((res) => {
          if (res.success) {
            this.$message.success({
              message: "修改成功",
              duration: 3 * 1000,
            });
            this.handleCancel();
          }
          this.dloading = false;
        });
    },
    formatSaleStatus(val) {
      switch (val) {
        case 1:
          return "未上架";
        case 2:
          return "审批中";
        case 3:
          return "已上架";
      }
    },
    handleCancel() {
      // 返回操作
      this.$router.go(-1);
    },
  },
};
</script>

<style lang="scss" scoped></style>
